<template>
  <el-dialog ref="importPropertyDialog" :title="$t('properties.import-from-csp').toUpperCase()" :visible="isVisible"
    custom-class="add-variable-dialog" @close="close()">
    <UiSearchBox ref="searchBox" :placeholder="$t('product.search_properties')" @input="filterProperties"></UiSearchBox>

    <UiTable v-if="propertiesAvailable" data-cy="csp-properties" :data="filteredProperties" class="ui_table_properties"
      variant="default">
      <el-table-column label="Name" :sortable="true" prop="name" width="500px">
        <template slot-scope="{ row }">
          <span v-if="getDimColor(row)" class="colorIndicator" :style="'background-color: ' + getDimColor(row)"></span>
          <el-tooltip :content="row.name" placement="bottom-start">
            <span>{{ row.name }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column v-if="columns.includes('definitionListId')" label="Definition list" :sortable="true"
        prop="definitionListId" :formatter="getDefinitionListName">
      </el-table-column>

      <el-table-column v-if="showPropertyType" label="Type" prop="valueType">
      </el-table-column>

      <el-table-column label="Include" width="90px">
        <template slot-scope="{ row }">
          <el-tooltip v-if="
            propertiesSelection[row.name] &&
            !propertiesSelection[row.name].compliant
          ">
            <i class="el-icon-error"></i>
            <template slot="content">
              <div>{{ $t(`properties.import-from-csp-invalid`) }}</div>
            </template>
          </el-tooltip>
          <UiToggle v-if="
            propertiesSelection[row.name] &&
            propertiesSelection[row.name].compliant
          " :key="row.name" :value="propertiesSelection[row.name].selected" data-test="import-property-toggle"
            @change="toggleSelection(...arguments, row)"></UiToggle>
        </template>
      </el-table-column>
    </UiTable>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isVisible = false">{{
        $t('action.cancel')
      }}</el-button>
      <el-button type="primary" @click="create">{{ $t('action.ok') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { JSONOperation } from '@axatechlab/cc-json-operations';
import { mapState } from 'vuex';
import { extractVarsFromExpression } from '../../../util';

export default {
  name: 'PropertyImporDialog',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    properties: {
      type: Array,
      default: () => []
    },
    defaultRelatedProperties: {
      type: Array,
      default: () => []
    },
    property: {
      type: String,
      default: ''
    },
    definitions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isVisible: false,
    propertiesSelection: {},
    switches: {},
    filteredProperties: []
  }),
  computed: {
    ...mapState('productProperty', {
      allProperties: 'properties'
    }),
    propertiesAvailable() {
      return this.properties && this.properties.length !== 0;
    },
    columns() {
      if (!this.propertiesAvailable) return [];
      return Object.keys(this.properties[0]);
    },
    showPropertyType() {
      return this.property !== 'dimensions';
    }
  },
  watch: {
    visible(value) {
      this.$refs.importPropertyDialog.$parent.$el.scrollTop = 0;
      this.propertiesSelection = {};
      if (value) this.formatModalData();
      this.isVisible = value;
    }
  },
  methods: {
    isCompliant(prop) {
      let valid = true;
      const inputsToCreate = [];
      if (prop.__type === 'COMPUTED') {
        const cspInputs = this.fetchInputByComputes(prop);
        for (let i = 0; i < cspInputs.length; i += 1) {
          const input = cspInputs[i];
          const property = this.allProperties.find(
            (p2) => p2.name === input.name
          );

          if (property) {
            if (property.type.toLowerCase() !== input.type.toLowerCase()) {
              valid = false;
              break;
            }
          } else {
            inputsToCreate.push(input);
          }
        }
      }
      return { valid, inputsToCreate };
    },
    formatModalData() {
      this.filteredProperties = this.properties;
      this.filteredProperties.forEach((p) => {
        const compliance = this.isCompliant(p);
        this.propertiesSelection[p.name] = {
          property: p,
          selected: false,
          compliant: compliance.valid,
          requiredInputs: compliance.inputsToCreate
        };
      });
    },
    getDimColor(row) {
      if (row.color === null) return '#bbbbbb';
      if (row.color) return row.color;
      return null;
    },
    getDefinitionListName(row) {
      if (row.definitionListId)
        return this.definitions.find((dl) => dl.key === row.definitionListId)
          .label;
      return '';
    },
    toggleSelection(val, property) {
      this.propertiesSelection[property.name].selected = val;
    },

    fetchInputByComputes(prop) {
      // fetch all inputs used by this computed
      const linkedInputs = [];
      const { expression } = prop.metadata || {};

      try {
        const response = JSONOperation.toString(
          expression.startsWith('{') ? JSON.parse(expression) : expression
        );

        if (response.status === 'SUCCESS') {
          // retrieve all inputs
          const nestedInputs = extractVarsFromExpression(response.result).map(
            (i) => i.replace('$', '')
          );

          nestedInputs.forEach((el) => {
            const inputFound = this.defaultRelatedProperties.find(
              (dp) => dp.name.toLowerCase() === el.toLowerCase()
            );
            if (inputFound) {
              linkedInputs.push(inputFound);
            }
          });
          return linkedInputs;
        }
        return [];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      }
    },

    create() {
      this.isVisible = false;
      const nestedInputs = [];
      const selectedProperties = Object.values(this.propertiesSelection)
        .filter((p) => p.selected !== false)
        .map((p) => {
          const prop = p.property;
          this.propertiesSelection[p.property.name].selected = false;
          p.requiredInputs.forEach((el) => {
            if (!nestedInputs.some((i) => i.name === el.name)) {
              const metadata = { ...el.metadata, inherit: true };
              Object.assign(el, { metadata });
              nestedInputs.push(el);
            }
          });
          const metadata = { ...prop.metadata, inherit: true };
          Object.assign(prop, { metadata });
          return prop;
        });
      this.$emit('create', selectedProperties.concat(nestedInputs));
    },
    filterProperties(val) {
      if (val.trim() === '') this.filteredProperties = this.properties;
      this.filteredProperties = this.properties.filter((p) =>
        p.name.toLowerCase().includes(val.toLowerCase())
      );
    },
    cancel() {
      this.isVisible = false;
      this.$emit('cancel');
    },
    close() {
      this.isVisible = false;
      this.$refs.searchBox.$refs.input.value = '';
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  ::v-deep .el-dialog__title {
    color: #00008f;
    font-weight: bold;
  }

  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }

  .ui_table_properties {
    ::v-deep .colorIndicator {
      display: inline-block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    ::v-deep th:first-child .cell,
    ::v-deep td:first-child .cell {
      padding-left: 2rem;
    }
  }

  .search-box {
    background-color: #f5f5f8;
    border-top: solid 2px #e4e7ed;
    border-bottom: solid 2px #e4e7ed;
    padding: 0 2rem;
    margin-bottom: 1rem;
  }
}
</style>
