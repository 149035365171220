/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Message } from 'element-ui';

export default {
  data: () => ({
    tokenName: '',
    MAX_TOKENS_PER_USER: 3
  }),
  methods: {
    validateNewToken() {
      const tokenNames = (this.tokens || []).map(token => token.name);
      const error = this.validateNewTokenName(this.tokenName, tokenNames);

      if (error) {
        return error;
      }

      if (this.tokens.length === this.MAX_TOKENS_PER_USER) {
        return this.$t('token.errors.max', { limit: this.MAX_TOKENS_PER_USER });
      }
    },
    async generateToken() {
      const error = this.validateNewToken();

      if (error) {
        Message.error(error);
        return;
      }

      const token = await this.createToken({
        name: this.tokenName,
        tenant: this.tenant.slug,
        user_id: this.user.id
      });

      const isResponseOk = this.notifyUserAndFetchTokens(token);

      if (isResponseOk) {
        this.tokenName = '';
      }
    },
  }
};
