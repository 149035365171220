/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import moment from 'moment';
import { Message } from 'element-ui';

export default {
  methods: {
    /**
     * @param {string} tokenName
     * @param {string[]} tokenNames
     */
    validateNewTokenName(tokenName, tokenNames) {
      if (tokenName === '' || !this.safeTokenName(tokenName)) {
        const error = this.$t('token.errors.invalid');
        return error;
      }

      if (tokenNames.includes(tokenName)) {
        const error = this.$t('token.errors.exists');
        return error;
      }
    },
    /** @param {string} tokenName */
    safeTokenName(tokenName) {
      const unsafeUriRegEx = /[:/?@$&',;=\s]/g;
      if (unsafeUriRegEx.test(tokenName)) {
        return false;
      }
      return true;
    },
    /** @param {{ last_used_at: number; }} token */
    modified(token) {
      const then = moment(token.last_used_at);
      const now = moment();
      const old = now.diff(then) > 24 * 3600 * 1000;
      return old ? then.format('lll') : then.fromNow();
    },
    /**
     * @param {{ hash: string }} token
     */
    notifyUserAndFetchTokens(token) {
      if (token && token.hash) {
        Message.success('Token successfully added');
        this.fetchTokens(this.tenant && this.tenant.slug);
        return true;
      } else {
        Message.error('There was an error with the token creation');
        return false;
      }
    },
    /**
     * @param {string} command
     * @param {number} index
     */
    async handleRowItemCommand(command, index) {
      switch (command) {
        case 'delete': {
          const responseToken = await this.deleteToken(this.tokens[index].id);
          if (responseToken.status && [200, 201].includes(responseToken.status)) {
            Message.success('Token successfully deleted');
          }
          this.fetchTokens(this.tenant && this.tenant.slug);
          break;
        }
        default:
          throw new Error(`Unknown command ${command}`);
      }
    }
  }
};
