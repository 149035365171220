/* eslint-disable no-param-reassign */
/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Message } from 'element-ui';
import Vue from 'vue';
import Router from 'vue-router';
import { productTypes } from './const/product';
import store from './store/index';
import { getAppRoutes } from './views/apps/index';
import DefinitionListsIndex from './views/DefinitionListsIndex.vue';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import LoginLegacy from './views/LoginLegacy.vue';
import NotFound from './views/NotFound.vue';
import Product from './views/Product.vue';
import ProductConfigurationWrapper from './views/product/configuration/Wrapper';
import ProductConfigurationIndex from './views/product/configuration/Index';
import ProductApps from './views/ProductApps.vue';
import ProductAppsIndex from './views/ProductAppsIndex.vue';
import ProductHome from './views/ProductHome.vue';
import ProductReleases from './views/ProductReleases.vue';
import ProductRules from './views/ProductRules.vue';
import ProductRulesEdit from './views/ProductRulesEdit.vue';
import ProductRulesIndex from './views/ProductRulesIndex.vue';
import ProductsIndex from './views/ProductsIndex.vue';
import ProductTermsEdit from './views/ProductTermsEdit.vue';
import ProductTermsIndex from './views/ProductTermsIndex.vue';
import ProductTests from './views/ProductTests.vue';
import ProductTestsEditor from './views/ProductTestsEditor.vue';
import Profile from './views/Profile.vue';
import SharedProperties from './views/SharedProperties.vue';
import RolesAndRights from './components/Roles/RolesAndRights.vue';
import Maintenance from './views/Maintenance.vue';

import app from './main.js';
import productImporters from './const/product.importers';

const Cookies = require('js-cookie');

async function retrieveProductAndVersion(to, from, next) {
  const urlParams = new URLSearchParams(window.location.search);
  let version = to.query.version || urlParams.get('version');
  const { productId } = to.params;

  // reload product only if not present in store
  if (
    !store.state.product.product ||
    (store.state.product.product &&
      (+productId !== +store.state.product.product.version.latest ||
        version !== store.state.product.product.version.current))
  ) {
    await store.dispatch('product/fetchProduct', {
      productId: to.params.productId,
      version
    });

    // Update URL with main product id if the requested id is from a product release
    // and add the correct version query param 
    if (
      store.state.product.product && (
        (parseInt(to.params.productId) !== parseInt(store.state.product.product.version.latest)
          || !version || store.state.product.product.version.current !== version))
    ) {
      next({
        name: to.name,
        params: {
          tenantSlug: to.params.tenantSlug,
          productId: store.state.product.product.version.latest,
          ruleId: store.state.product.product.rules ? store.state.product.product.rules[0].id : null
        },
        query: {
          version: store.state.product.product.version.current
        }
      });
    }
  }

  if (
    store.state.product.product &&
    store.state.product.product.technicalName &&
    productId.toString().toLowerCase() ===
    store.state.product.product.technicalName.toString().toLowerCase()
  ) {
    next({
      ...to,
      params: {
        ...to.params,
        productId: store.state.product.product.version.latest
      }
    });
    return true;
  }

  // if no product has been loaded it surely does not exist, redirect back to products index
  if (!store.state.product.product) {
    store.dispatch('productRelease/setUserActiveProductRelease', {
      productId,
      shadowProductId: productId,
      version: undefined
    });
    next('/404');
    return false;
  }

  window.localStorage.setItem(
    'productLocale',
    store.state.product.product.defaultLanguage
  );

  next();
  return true;
}

async function handleTenantSubroutines(to, from, next) {
  await store.dispatch('products/fetchProductSources');
  next();
  return true;
}

Vue.use(Router);
const loginRoutes =
  window.__env__.VUE_APP_DEFAULT_LOGIN === 'openid'
    ? [
      {
        path: '/auth/login',
        name: 'auth-login',
        component: Login,
        meta: {
          guest: true
        }
      },
      {
        path: '/admin-login',
        name: 'auth-login',
        component: LoginLegacy,
        meta: {
          guest: true
        }
      }
    ]
    : [
      {
        path: '/auth/login',
        name: 'auth-login',
        component: LoginLegacy,
        meta: {
          guest: true
        }
      }
    ];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '*', component: NotFound },
    { path: '/404', component: NotFound },
    ...loginRoutes,
    {
      path: '/roles',
      name: 'roles',
      component: RolesAndRights
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile
    },
    { path: '/token', name: 'token', redirect: '/profile' },
    {
      path: '/t/:tenantSlug/',
      component: Home,
      beforeEnter: handleTenantSubroutines,
      children: [
        {
          path: '/',
          redirect: 'products'
        },
        {
          path: 'definitions',
          name: 'definition-lists-index',
          component: DefinitionListsIndex
        },
        {
          path: 'templates',
          name: 'templates-index',
          props: (route) => ({
            productType: productTypes.TEMPLATE,
            tenantSlug: route.params.tenantSlug
          }),
          meta: {
            type: productTypes.TEMPLATE
          },
          component: ProductsIndex
        },
        {
          path: 'products',
          name: 'products-index',
          props: (route) => ({
            productType: productTypes.PRODUCT,
            tenantSlug: route.params.tenantSlug
          }),
          meta: {
            type: productTypes.PRODUCT
          },
          component: ProductsIndex
        },
        {
          path: 'properties/:type',
          name: 'properties-index',
          props: (route) => ({
            tenantSlug: route.params.tenantSlug
          }),
          component: SharedProperties,
          force: true
        },
        {
          path: 'questionnaires',
          name: 'questionnaires-index',
          props: (route) => ({
            productType: productTypes.QUESTIONNAIRE,
            tenantSlug: route.params.tenantSlug
          }),
          meta: {
            type: productTypes.QUESTIONNAIRE
          },
          component: ProductsIndex
        }
      ]
    },
    {
      path: '/t/:tenantSlug/definitions/:definitionListId(\\d+)',
      name: 'definition-lists-edit',
      // Load component asynchronously since it requires a large dependency (xlsx)
      component: () => import('./views/DefinitionListsEdit.vue'),
      props: (route) => ({
        definitionListId: route.params.definitionListId
      })
    },
    {
      path: '/t/:tenantSlug/products/:productId',
      component: Product,
      props: (route) => ({
        productId: route.params.productId,
        onRouteUpdate: retrieveProductAndVersion
      }),
      beforeEnter: retrieveProductAndVersion,
      children: [
        {
          path: '',
          name: 'product-home',
          component: ProductHome,
          props: true
        },
        {
          path: 'rules',
          component: ProductRules,
          beforeEnter: (to, from, next) => {
            if (!store.state.product) {
              next();
            }
            if (
              to.params.ruleId &&
              store.state.product.product.rules
                .map((r) => r.id)
                .includes(to.params.ruleId)
            ) {
              next();
            } else {
              next({
                name: 'product-rules-edit',
                params: {
                  ...to.params,
                  ruleId: store.state.product.product.rules[0].id
                },
                query: to.query
              });
            }
          },
          children: [
            {
              path: '',
              name: 'product-rules-index',
              props: true,
              component: ProductRulesIndex
            },
            {
              path: ':ruleId(\\d+)',
              name: 'product-rules-edit',
              props: true,
              component: ProductRulesEdit
            }
          ]
        },
        {
          path: 'terms',
          name: 'product-terms-index',
          component: ProductTermsIndex,
          props: true
        },
        {
          path: 'terms/:termId',
          name: 'product-terms-edit',
          component: ProductTermsEdit,
          props: true
        },
        {
          path: 'configuration',
          component: ProductConfigurationWrapper,
          children: [
            {
              path: '',
              name: 'product-configuration',
              component: ProductConfigurationIndex,
              props: true
            },
            {
              path: ':propertyType/:propertyName?',
              name: 'product-configuration-direct',
              component: ProductConfigurationIndex,
              props: true
            }
          ]
        },
        {
          path: 'releases',
          name: 'product-releases',
          component: ProductReleases,
          props: true,
          beforeEnter: (to, from, next) => {
            if (store.state.product.product) {
              if (store.state.product.product.source === productImporters.IMPORT_LIGHT) {
                app.$bvModal.msgBoxOk(
                  app.$t('product.import_light_releases_disabled')
                );
                next({ ...to, name: 'product-home' });
                return;
              }
              next();
            }
          }
        },
        {
          path: 'tests',
          name: 'product-tests',
          component: ProductTests,
          props: true
        },
        {
          path: 'tests/:testId',
          name: 'product-tests-editor',
          component: ProductTestsEditor
        },
        {
          path: 'apps',
          name: 'product-apps-container',
          props: true,
          component: ProductApps,
          beforeEnter: (to, from, next) => {
            if (store.state.product.product) {
              if (store.state.product.product.source === productImporters.IMPORT_LIGHT) {
                app.$bvModal.msgBoxOk(
                  app.$t('product.import_light_apps_disabled')
                );
                next({ name: 'product-home' });
                return;
              }
              const shouldAllowApplications =
                store.state.product.product.version.list.length > 0 ||
                productTypes.TEMPLATE === store.state.product.product.type;
              if (shouldAllowApplications) {
                next();
              } else {
                app.$bvModal.msgBoxOk(
                  app.$t('product.versions.releaseMandatory')
                );
                next({
                  name: 'product-releases',
                  params: to.params,
                  query: to.query
                });
              }
            }
          },
          children: [
            {
              path: '',
              name: 'product-apps',
              props: true,
              component: ProductAppsIndex
            },
            ...getAppRoutes()
          ]
        }
      ]
    }
  ]
});

// Check redirect path afther succesfull login
router.beforeResolve((to, from, next) => {
  let isLogin = false;
  loginRoutes.forEach((r) => {
    if (r.path === to.path) {
      isLogin = true;
      next();
    }
  });

  if (!isLogin) {
    const cookieName = 'loginRedirectPath';
    const redirectPath = Cookies.get(cookieName);
    if (!redirectPath) {
      next();
    } else {
      Cookies.remove(cookieName);
      window.location = redirectPath;
    }
  }
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

function isSameProduct(to, from) {
  if (to.params && from.params) {
    return (
      to.params.tenantSlug === from.params.tenantSlug &&
      to.params.productId === from.params.productId
    );
  }
  return false;
}

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    if (to.query.field) {
      to.query.field = '';
    }

    if (to.query.page) {
      to.query.page = '1';
    }
  }

  if (isSameProduct(to, from) && !hasQueryParams(to) && hasQueryParams(from)) {
    next({ ...to, query: from.query });
  } else {
    next();
  }
});

async function getUser() {
  const user = store.getters['auth/getUser'];
  if (user) return user;
  const AUTH_COOKIE = window.__env__.VUE_APP_AUTH_COOKIE;
  const accessToken = Cookies.get(AUTH_COOKIE);
  if (!accessToken) return null;
  await store.dispatch('auth/recallLogin', { accessToken });
  return store.getters['auth/getUser'];
}

router.beforeEach(async (to, from, next) => {
  const user = await getUser();
  if (!user) return next();
  const isSuperAdmin = store.getters['auth/isSuperAdmin'];
  const validTenantSlugs = user.teams.map((t) => t.tenant);
  const { tenantSlug = store.state.auth.tenant ?? validTenantSlugs[0] } =
    to.params;
  if (!tenantSlug || tenantSlug.trim() === '') {
    throw new Error('Unable to get the current tenant slug');
  }
  if (!validTenantSlugs.includes(tenantSlug) && !isSuperAdmin()) {
    return next({ path: '/' });
  }
  await store.dispatch('auth/setTenant', { tenant: tenantSlug });

  if (['/roles', '/profile', '/maintenance', '/auth/login'].includes(to.path))
    return next();

  const { path: currentPath } = to;

  const pathMatch = currentPath.match(/^\/t\//);
  if (pathMatch && pathMatch[0] === '/t/') return next();

  const nextPath = `/t/${tenantSlug}${to.path}`;

  return next({ path: nextPath });
});

router.beforeEach(async (to, from, next) => {
  if (
    to.meta.guest ||
    to.path.includes('auth') ||
    to.path.includes('admin-login')
  ) {
    return next();
  }
  const user = await getUser();
  const AUTH_COOKIE = window.__env__.VUE_APP_AUTH_COOKIE;
  const accessToken = Cookies.get(AUTH_COOKIE);
  if (user && accessToken) {
    return next();
  }
  await store.dispatch('auth/logOut');
  Message.info('Please log in to access this page');
  return next(`/auth/login?intended=${to.fullPath}`);
});

export default router;
