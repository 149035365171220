<template>
  <div class="d-flex flex-grow-1 align-items-stretch overflow-hidden">
    <b-tabs v-model="activeTabIndex" pills vertical
      class="flex-shrink-0 border-right border-tabs-color m-0 left-tabs pr-0"
      active-nav-item-class="active-left-tab text-primary">
      <b-tab v-for="(type, index) in typesArray" :key="type.value" class="text-primary"
        :title-link-class="{ 'opacity-50': activeTabIndex !== index }" :title="type.text" />
    </b-tabs>
    <div class="px-2 flex-grow-1 d-flex flex-column overflow-hidden">
      <UiPaddedArea class="p-0 flex-shrink-0">
        <UiToolbar>
          <b-dropdown v-if="tabIsProperty" variant="primary" text="Filters">
            <b-dropdown-form>
              <b-form-checkbox v-model="showOnlyMissingCsp">
                {{ $t('product.filter-not-in-csp') }}
              </b-form-checkbox>
              <b-form-checkbox v-model="showOnlyMissingUnused">
                {{ $t('product.filter-unused-properties') }}
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
          <div class="flex-spacer" />
          <b-input-group class="search-group">
            <b-form-input v-model="searchStr" class="search-input" :placeholder="$t(`product.search_properties`)" />
            <b-icon icon="search" class="search-input-icon"></b-icon>
          </b-input-group>
          <div class="flex-spacer" />
          <b-button variant="primary" :disabled="isReadOnlyMode"
            @click="createNew">
            <i class="fas fa-plus mr-2"></i>{{
              $t('product.add', {
                property:
                  activeTab[activeTab.length - 1] === 's'
                    ? activeTab.slice(0, -1)
                    : activeTab
              })
            }}
          </b-button>

          <b-button v-if="copilotActivated &&
            !isGuest(tenant) &&
            activeTab === 'dimensions' &&
            getCopilotStatus !== undefined
          " v-b-toggle.copilot-collapse variant="outline-indigo">
            <b-icon-stars />
            <b-icon-chevron-down class="when-closed" />
            <b-icon-chevron-up class="when-open" />
          </b-button>
          <b-button v-if="tabIsProperty" :disabled="isReadOnlyMode" variant="outline-primary" @click="importFromCsp">
            <i class="fas fa-upload"></i>
            {{ $t('properties.import-from-csp') }}
          </b-button>

          <template #side>
            <b-dropdown v-if="activeTab === 'metadata'" variant="outline-primary" :disabled="isReadOnlyMode" no-caret
              right>
              <template #button-content>
                <b-icon-three-dots />
              </template>
              <b-dropdown-item>
                <UiButton v-if="product.version.current === 'initial' && product.locked
                " icon="el-icon-unlock" class="ui_button_unlock_metadata px-2" :data-cy="'property-unlock-button'"
                  :disabled="!canLockOrUnlock" @click="handleUnlock">
                  {{ $t('product.unlock') }}
                </UiButton>
              </b-dropdown-item>
              <b-dropdown-item>
                <UiButton v-if="product.version.current === 'initial' && !product.locked
                " icon="el-icon-lock" class="ui_button_unlock_metadata px-2" :data-cy="'property-unlock-button'"
                  :disabled="!canLockOrUnlock" @click="handleLock">
                  {{ $t('product.lock') }}
                </UiButton>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item>
                <UseAsTemplate v-if="(isProduct || isTemplate) &&
                  !product.locked &&
                  !isReadOnlyMode
                " :is-template="isTemplate" :is-read-only="isReadOnlyMode" :templates="[]" class="px-2"
                  @update="onUpdateIsTemplate" />
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </UiToolbar>
        <b-collapse v-if="copilotActivated && getCopilotStatus !== undefined" id="copilot-collapse" visible>
          <div v-if="activeTab === 'dimensions'"
            class="d-flex position-relative mt-3 px-4 py-2 gap-2 align-items-center justify-content-center bg-white border border-indigo text-indigo"
            style="min-height: 48px">
            <div v-if="!copilotReady" class="text-indigo position-absolute d-flex align-items-center"
              style="left: 16px; top: 0; bottom: 0">
              <b-icon-stars />
              <span>
                {{ $t('copilot_processing_documents') }}
              </span>
              <b-spinner small class="ml-1" />
            </div>
            <template v-else>
              <div class="text-indigo position-absolute d-flex align-items-center"
                style="left: 16px; top: 0; bottom: 0">
                <b-icon-stars />
                <span>
                  {{ $t('copilot_suggestions') }}
                </span>
              </div>

              <b-button v-for="dim of extractionDimensionsNotYetInProduct" :key="dim.name" size="sm" variant="indigo"
                :disabled="dim.status !== 'success'" @click="extractDimension(dim.name)">
                <b-spinner v-if="['queued', 'started'].includes(dim.status)" />
                <b-icon-stars v-else />
                {{ $t('copilot_extract', { name: dim.label }) }}
              </b-button>

              <b-button v-b-modal.modalExtract size="sm" variant="outline-indigo">
                <b-icon-stars />
                {{ $t('copilot_extract', { name: 'CUSTOM' }) }}
              </b-button>
            </template>
          </div>
          <div v-if="extractedPendingDimensions && extractedPendingDimensions.length
          " class="mt-3 px-4 py-2 gap-2 bg-white border text-indigo">
            <b-row v-for="dim in extractedPendingDimensions" :key="dim.label">
              {{ $t('copilot_dimenstion_extract') }} '{{ dim.label }}' ({{
                dim.status
              }})
            </b-row>
          </div>
        </b-collapse>
        <b-modal id="modalExtract" title="Extract custom dimension" ok-only ok-title="Extract" @close="extractName = ''"
          @ok="
            extractDefinitionsForDimension({
              productId: product.id,
              dimensionName: extractName
            })
            ">
          <b-form-input v-model="extractName" :placeholder="$t('Name of the dimension you want to extract from the PDF.')
            " />
        </b-modal>
      </UiPaddedArea>
      <template v-if="tabIsProperty">
        <ProductProperties ref="properties" :property="activeTab" :label="$t('product.' + activeTab.slice(0, -1))"
          :columns="computedColumns" :types="computedTypes" :dialect-types="outputsDialectTypes"
          :properties="computedProperties" :default-related-properties="filteredInputs"
          :property-metadata="computedPropertyMetadataByType" :shared-properties="computedSharedProperties"
          :shared-properties-all="sharedPropertiesAll" :definitions="definitionListSuggestions" :search="searchStr"
          :highlighted-property="activeProperty" />
      </template>
      <template v-if="activeTab === 'questionnaires'">
        <ProductQuestionnaire ref="questionnaires" :filter-query="searchStr" />
      </template>
      <template v-if="activeTab === 'metadata'">
        <ProductMetadata ref="metadata" :label="$t('product.metadata')" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { propertyMetadataByType } from '../../../components/Metadata/PropertyMetadataMixin';
import ProductProperties from '../../../components/ProductProperties.vue';
import ProductMetadata from '../../../components/Product/ProductMetadata.vue';
import ProductQuestionnaire from '../../../components/ProductQuestionnaires.vue';
import ProductSaveMixin from '../../../components/ProductSaveMixin';
import UseAsTemplate from '../../../components/Product/UseAsTemplate.vue';
import { productTypes } from '../../../const/product';
import { generateHex } from '../../../helpers';
import RoutingMixin from '../../../components/RoutingMixin';
import ComputedMixin from '@axa-getd/ui-components/src/mixins/ComputedMixin';

const routableTabName = [
  'dimensions',
  'inputs',
  'computes',
  'outputs',
  'units',
  'endorsements',
  'questionnaires',
  'metadata'
];

export default {
  name: 'ProductConfigurationIndex',
  components: {
    ProductProperties,
    ProductQuestionnaire,
    ProductMetadata,
    UseAsTemplate
  },
  mixins: [ProductSaveMixin, RoutingMixin, ComputedMixin],
  data: () => ({
    searchStr: '',
    activeProperty: null,
    defaultTypes: [
      'Number',
      'MonetaryAmount',
      'Date',
      'DateTime',
      'Boolean',
      'Text'
    ],
    unitTypes: ['MonetaryAmount', 'Distance', 'Number', 'Night'],
    endorsementTypes: ['Text'],
    metadataTypes: ['Text', 'DateTime'],
    outputsDialectTypes: ['NONE', 'LIMIT', 'EXCESS', 'DEDUCTIBLE'],
    dimensionColumns: [
      'expand',
      'color',
      'valid',
      'name',
      'displayName',
      'priority',
      'definitionList',
      'API',
      'fields'
    ],
    inputColumns: [
      'expand',
      'valid',
      'name',
      'displayName',
      'type',
      'priority',
      'fields'
    ],
    computeColumns: ['expand', 'name', 'displayName'],
    outputColumns: [
      'expand',
      'valid',
      'name',
      'definitionList',
      'displayName',
      'type',
      'dialectType',
      'fields'
    ],
    unitColumns: ['id', 'valid', 'name', 'displayName', 'type', 'fields'],
    endorsementColumns: ['id', 'name', 'displayName'],
    showOnlyMissingCsp: false,
    showOnlyMissingUnused: false,
    typesArray: [
      { value: 'dimensions', text: 'Dimensions' },
      { value: 'inputs', text: 'Input variables' },
      { value: 'computes', text: 'Computed variables' },
      { value: 'outputs', text: 'Outputs' },
      { value: 'units', text: 'Units' },
      { value: 'endorsements', text: 'Endorsements' },
      { value: 'questionnaires', text: 'Questionnaires' },
      { value: 'metadata', text: 'Metadata' }
    ],
    copilotExpanded: true,
    extractName: '',
    activeTab: 'dimensions'
  }),
  computed: {
    ...mapState('definitionListSuggestions', {
      definitionListSuggestions: 'suggestions'
    }),
    ...mapState('auth', ['tenant']),
    ...mapGetters('product', [
      'isReadOnly',
      'getCopilotStatus',
      'copilotActivated',
      'extractedDimensions',
      'extractedPendingDimensions'
    ]),
    ...mapState('sharedProperty', { sharedPropertiesAll: 'properties' }),
    ...mapGetters('sharedProperty', {
      sharedPropertiesDimensions: 'dimensions',
      sharedPropertiesInputs: 'inputs',
      sharedPropertiesComputes: 'computes',
      sharedPropertiesOutputs: 'outputs',
      sharedPropertiesUnits: 'units'
    }),
    ...mapGetters('productProperty', [
      'dimensions',
      'inputs',
      'computes',
      'outputs',
      'units',
      'endorsements',
      'unusedProperties'
    ]),
    ...mapGetters('auth', ['isGuest', 'isAdmin', 'isOwner']),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    canLockOrUnlock() {
      return (
        this.isAdmin(this.product.team.slug) ||
        this.isOwner(this.product.team.slug) ||
        this.isAdmin(this.tenant) ||
        this.isOwner(this.tenant)
      );
    },
    activeTabIndex: {
      get() {
        return this.typesArray.findIndex((t) => t.value === this.activeTab);
      },
      set(activeTabIndex) {
        const activeTabValue = this.typesArray[activeTabIndex].value;
        if (!routableTabName.includes(activeTabValue)) {
          throw new Error(`Invalid tab name (${activeTabValue})`);
        }
        this.clearSearch();
        this.setProductConfigActiveTab({ activeTabValue });
        this.activeTab = activeTabValue;
      }
    },
    productType() {
      return this.product.type.toLowerCase();
    },
    isShadowProduct() {
      return productTypes.SHADOW_PRODUCT === this.product.type;
    },
    isNotQuestionnaire() {
      return ![
        productTypes.QUESTIONNAIRE,
        productTypes.SHADOW_QUESTIONNAIRE
      ].includes(this.product.type);
    },
    defaultRelatedProperties() {
      return this.filteredProperties(this.sharedPropertiesInputs).concat(
        this.filteredProperties(this.sharedPropertiesComputes)
      );
    },
    tabIsProperty() {
      return [
        'dimensions',
        'inputs',
        'computes',
        'outputs',
        'units',
        'endorsements'
      ].includes(this.activeTab);
    },
    computedTypesArray() {
      return this.isNotQuestionnaire
        ? this.typesArray
        : this.typesArray.filter((t) => t.value === 'questionnaires');
    },
    computedTypes() {
      switch (this.activeTab) {
        case 'units':
          return this.unitTypes;
        default:
          return this.defaultTypes;
      }
    },
    computedColumns() {
      switch (this.activeTab) {
        case 'dimensions':
          return this.dimensionColumns;
        case 'inputs':
          return this.inputColumns;
        case 'computes':
          return this.computeColumns;
        case 'outputs':
          return this.outputColumns;
        case 'units':
          return this.unitColumns;
        case 'endorsements':
          return this.endorsementColumns;
        default:
          return undefined;
      }
    },
    filteredDimensions() {
      return this.filteredProperties(this.dimensions);
    },
    filteredInputs() {
      return this.filteredProperties(this.inputs);
    },
    filteredComputes() {
      return this.filteredProperties(this.computes);
    },
    filteredOutputs() {
      return this.filteredProperties(this.outputs);
    },
    filteredUnits() {
      return this.filteredProperties(this.units);
    },
    filteredEndorsements() {
      return this.filteredProperties(this.endorsements);
    },
    computedProperties() {
      switch (this.activeTab) {
        case 'dimensions':
          return this.filteredDimensions;
        case 'inputs':
          return this.filteredInputs;
        case 'computes':
          return this.filteredComputes;
        case 'outputs':
          return this.filteredOutputs;
        case 'units':
          return this.filteredUnits;
        case 'endorsements':
          return this.filteredEndorsements;
        default:
          return [];
      }
    },
    computedPropertyMetadataByType() {
      return propertyMetadataByType(this.activeTab);
    },
    computedSharedProperties() {
      switch (this.activeTab) {
        case 'dimensions':
          return this.sharedPropertiesDimensions;
        case 'inputs':
          return this.sharedPropertiesInputs;
        case 'computes':
          return this.sharedPropertiesComputes;
        case 'outputs':
          return this.sharedPropertiesOutputs;
        case 'units':
          return this.sharedPropertiesUnits;
        default:
          return [];
      }
    },
    isTemplate() {
      return productTypes.TEMPLATE === this.product.type;
    },
    isProduct() {
      return productTypes.PRODUCT === this.product.type;
    },
    copilotReady() {
      return this.copilotActivated && this.getCopilotStatus === 'success';
    },
    extractionDimensionsNotYetInProduct() {
      return this.extractedDimensions.filter(
        (dim) => !this.hasDimension(dim.name)
      );
    }
  },
  watch: {
    $route(to, from) {
      if (from.name !== to.name) return;
      if (to.params.propertyType) {
        this.activeTab = to.params.propertyType;
      }
      this.activeProperty = to.params.propertyName || null;
    },
    product(after, before) {
      if (before.id !== after.id) {
        this.fetchProductProperties(this.product.id);
        this.fetchDimensions(this.product.id);
      }
    },
    activeTab() {
      if (!routableTabName.includes(this.activeTab)) return;
      this.activeProperty = null;
      this.changeRouteWithCatch({
        name: 'product-configuration-direct',
        params: {
          ...this.$route.params,
          propertyType: this.activeTab,
          propertyName: null
        }
      });
    }
  },
  beforeMount() {
    this.selectDirectPropertyOrTab();
  },
  async mounted() {
    this.fetchSharedProperties();
    this.fetchProductProperties(this.product.id);
    await this.fetchDefinitions();
    this.fetchDimensions(this.product.id);
    if (this.isNotQuestionnaire) {
      this.dimensionColumns.push('allowMulitple');
    }
    this.fetchProductExtractedDimensions(this.product.id);
  },
  methods: {
    ...mapActions('sharedProperty', ['fetchSharedProperties']),
    ...mapActions('definitionListSuggestions', { fetchDefinitions: 'fetch' }),
    ...mapActions('product', [
      'setProductConfigActiveTab',
      'toggleLock',
      'markAsTemplate',
      'markAsProduct',
      'fetchProductExtractedDimensions',
      'extractDefinitionsForDimension'
    ]),
    ...mapActions('productProperty', [
      'fetchProductProperties',
      'fetchDimensions',
      'createProductProperty',
      'createTermsForDimensionFromCopilot'
    ]),
    handleTabInput(name) {
      if (!routableTabName.includes(name)) return;
      this.activeProperty = null;
      this.changeRouteWithCatch({
        name: 'product-configuration-direct',
        params: {
          ...this.$route.params,
          propertyType: name,
          propertyName: null
        }
      });
    },
    selectDirectPropertyOrTab() {
      const { propertyType, propertyName } = this.$route.params;
      if (!propertyType) return;

      if (propertyType && propertyName) {
        this.selectDirectProperty(propertyType, propertyName);
      } else if (propertyType && !propertyName) {
        this.selectDirectTab(propertyType);
      }
    },
    selectDirectProperty(tab, name) {
      if (!this.assertValidTabName(tab)) return;
      this.activeTab = tab;
      this.activeProperty = name;
    },
    selectDirectTab(tab) {
      if (!this.assertValidTabName(tab)) return;
      this.activeTab = tab;
      this.activeProperty = null;
    },
    assertValidTabName(tab) {
      if (!routableTabName.includes(tab)) {
        this.$router.push({
          name: 'product-configuration'
        });
        return false;
      }
      return true;
    },
    clearSearch() {
      this.searchStr = '';
    },
    propertyMatchSearch(query, value) {
      if (value && typeof value === 'string') {
        return value.toLowerCase().includes(query.toLowerCase());
      }
      if (value && typeof value === 'object' && value.displayName) {
        return Object.entries(value.displayName).some((val) =>
          this.propertyMatchSearch(query, val[1])
        );
      }
      return false;
    },
    flagNestedInputs(inputs) {
      const nestedInputs = {};
      this.sharedPropertiesComputes.map((c) => {
        const { metadata } = c;
        const computeNestedVars = this.extractVarsFromExpression(
          this.getValidCCJsonStringEpxr(metadata.expression || '{}')
        );
        inputs.map((i) => {
          if (computeNestedVars.includes(`$${i.name}`)) {
            if (!nestedInputs[i.name]) {
              nestedInputs[i.name] = {
                hosts: []
              };
            }
            nestedInputs[i.name].hosts.push(c.name);
          }
          return i;
        });
        return c;
      });
      return nestedInputs;
    },
    filteredProperties(properties) {
      if (!this.searchStr || this.searchStr.trim().length === 0) {
        const propertiesFiltered = properties
          .filter((p) => {
            if (!this.showOnlyMissingCsp) {
              return true;
            }
            const { errors } = this.productValidations[this.product.id];
            const propertyInError = errors.find((e) => e.property === p.name);
            return (
              propertyInError &&
              propertyInError.code === 'MISSING-PROPERTY-IN-CSP'
            );
          })
          .filter((p) => {
            if (!this.showOnlyMissingUnused) {
              return true;
            }
            let found = false;
            Object.keys(this.unusedProperties).forEach((propertyType) => {
              if (this.unusedProperties[propertyType].includes(p.name)) {
                found = true;
              }
            });
            return found;
          });
        return propertiesFiltered;
      }

      return properties.filter((p) => {
        return (
          this.propertyMatchSearch(this.searchStr, p.name) ||
          this.propertyMatchSearch(this.searchStr, p.metadata)
        );
      });
    },
    propertyMetadataByType(type) {
      return propertyMetadataByType(type);
    },
    importFromCsp() {
      if (this.$refs.properties) {
        this.$refs.properties.showImportDialog();
      }
    },
    createNew() {
      switch (this.activeTab) {
        case 'dimensions':
        case 'inputs':
        case 'computes':
        case 'outputs':
        case 'units':
        case 'endorsements':
          if (this.$refs.properties) {
            this.$refs.properties.showCreationRow();
          }
          break;
        case 'questionnaires':
          if (this.$refs.questionnaires) {
            this.$refs.questionnaires.showDialog();
          }
          break;
        case 'metadata':
          if (this.$refs.metadata) {
            this.$refs.metadata.showCreationDialog();
          }
          break;
        default:
          break;
      }
    },
    handleUnlock() {
      if (this.product.locked === false) return;
      this.toggleLock({ teamSlug: this.product.team.slug });
    },
    handleLock() {
      if (this.product.locked === true) return;
      this.toggleLock({ teamSlug: this.product.team.slug });
    },
    onUpdateIsTemplate(value) {
      if (value.isTemplate) {
        this.markAsTemplate({ templateTypeId: value.templateTypeId });
      } else {
        this.markAsProduct({});
      }
    },
    extractDimension(name) {
      this.createProductProperty({
        productId: this.product.id,
        propertyData: {
          name,
          type: 'Enum',
          color: generateHex(name),
          __type: 'DIMENSION'
        },
        tenant: this.tenant
      }).then(() => {
        this.createTermsForDimensionFromCopilot({
          productId: this.product.id,
          dimension: name
        });
      });
    },
    hasDimension(name) {
      return this.computedProperties.find((prop) => prop.name === name);
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.only-show-predefined-colors {

  .el-color-dropdown__value,
  .el-color-dropdown__main-wrapper {
    display: none;
  }
}

.form-control {
  max-width: initial;
}

.el-checkbox {
  margin-right: 10px;
}

.error {
  color: #cc0000;
}

.subtitle {
  color: #909399;
  font-weight: bold;
}

.priority {
  width: 100%;
}

.el-button {
  text-transform: none;
  font-weight: 400;
}

.el-button.is-circle {
  border-width: 0;
  background-color: transparent;
  margin: 0;
  padding: 5px;
}

.ui_button_unlock_metadata {
  width: 100%;
}

.gap-2 {
  gap: 8px;
}

.collapsed>.when-open,
.not-collapsed>.when-closed {
  display: none;
}
</style>
