<template>
  <nav v-selector class="px-5">
    <ul class="d-flex gap-6">
      <li v-for="{
        name,
        label,
        iconClass,
        isBeta,
        tests = false,
        exact = false
      } in links" :key="name" class="d-flex align-items-stretch">
        <router-link v-if="!hasError() || name !== 'product-tests'" :to="to(name, product, $route)" :exact="exact"
          class="d-flex flex-column align-items-center justify-content-center flex-xl-row d-xl-inline-flex py-0"
          :class="'ui_link_' + name" :data-cy="'tab-' + name">
          <div v-if="iconClass" :class="'fontIcon d-lg-none d-block mr-lg-2 ' + iconClass" />
          <div class="label d-none d-lg-flex align-items-center justify-content-center gap-1"
            data-cy="product-tab-name">
            <div class="d-flex uppercase">
              {{ label }}
              <span v-if="isBeta" class="beta-label">BETA</span>
            </div>
            <div v-if="tests" class="d-none d-xl-block" :class="tests.success === tests.total
              ? 'subtitle'
              : 'subtitle text-danger'
              ">
              <div v-if="
                !hasError() &&
                !stats.isLoading &&
                stats.progress === undefined
              " class="fontIcon" :class="!tests.total ? '' : (tests.success === tests.total
                ? 'icon-check-circle-fill'
                : 'icon-alert-circle')">
              </div>
              <div v-else-if="hasError()" class="text-danger el-icon-refresh" @click.stop="fetchTests()">
                Error!
              </div>
              <div v-else v-selector class="loading-spinner is-active">
                <b-icon icon="arrow-clockwise" animation="spin" font-scale="1"></b-icon>
              </div>
            </div>
          </div>
        </router-link>

        <a v-else class="d-flex flex-column align-items-center flex-xl-row d-xl-inline-flex py-0"
          style="cursor: pointer" :class="'ui_link_' + name" @click="fetchTests()">
          <div v-if="iconClass" :class="'fontIcon mr-lg-2 ' + iconClass" />
          <div class="label d-none d-lg-block" data-cy="product-tab-name">
            <div class="d-flex uppercase">
              {{ label }}
              <span v-if="isBeta" class="beta-label">BETA</span>
            </div>
            <div v-if="subtitle" class="subtitle d-none d-xl-block">
              {{ subtitle }}
            </div>
            <div class="d-none d-xl-block subtitle text-danger">
              <div class="text-danger el-icon-refresh">Error!</div>
            </div>
          </div>
        </a>
      </li>
      <div class="search-toolbar d-flex align-items-center">
        <div :class="'search-tab d-flex align-items-center' + (searchSidebarVisible ? ' on' : '')">
          <div :class="'d-flex gap-1 align-items-center uppercase search-button' + (searchSidebarVisible ? ' on' : '')"
            @click="toggleSidebar">
            <i class="icon-search"></i>
            {{ $t('common.search') }}
          </div>
        </div>
      </div>
      <!--
        We only show this toolbar when showing the Rule Editor.
        We're setting a key on the component so that it gets updated when the router path changes.
        And we're using a prop to hide the component because using v-if completely removes it when we're not
        on the Rule Editor, which then prevents the :key trick to work.
      -->
      <rules-editor-toolbar :key="'tb-' + $route.path" :visible="showRulesEditorToolbar" :read-only="isReadOnly"
        :rule-id="parseInt($router.currentRoute.params.ruleId)" />
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RulesEditorToolbar from './RulesEditorToolbar.vue';
import { productTypes } from '../const/product';
import { productReleaseLabel } from '../domain/model/productRelease';
import productImporters from '@/const/product.importers';

export default {
  name: 'ProductNav',
  components: {
    RulesEditorToolbar
  },
  props: {
    product: {
      required: true,
      type: Object
    },
    stats: {
      required: true,
      type: Object
    },
    productType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      searchSidebarIsVisible: false
    };
  },
  computed: {
    ...mapState('uiProductSearch', ['searchSidebarVisible']),
    isReadOnly() {
      if (!this.product) return false;
      const { ruleId } = this.$router.currentRoute.params;
      const filtered = this.product.rules.filter((r) => r.id === ruleId);
      return filtered.length ? !!filtered[0].is_locked : false;
    },
    links() {
      return [
        {
          label: this.$t('product.nav.overview'),
          name: `product-home`,
          iconClass: 'icon-view-1',
          exact: true
        },
        {
          label: this.$t('product.nav.rules'),
          name: `product-rules-index`,
          iconClass: 'icon-hierarchy-2'
        },
        {
          label: this.$t('product.nav.terms'),
          name: `product-terms-index`,
          iconClass: 'icon-layout-module'
        },
        {
          label: this.$t('product.nav.configuration'),
          name: `product-configuration`,
          iconClass: 'icon-cog-circle'
        },
        {
          label: this.$t('product.nav.releases'),
          name: `product-releases`,
          iconClass: 'icon-tags-double-1',
          hide: () =>
            this.product.source === productImporters.IMPORT_LIGHT ||
            (!this.isProduct(this.product) && !this.isQuestionnaire()),
          isBeta: false
        },
        {
          label: this.$t('product.nav.tests'),
          name: `product-tests`,
          iconClass: 'icon-checklist',
          isBeta: false,
          hide: () =>
            this.product.source === productImporters.IMPORT_LIGHT || this.isQuestionnaire(),
          tests: this.stats
        },
        {
          label: this.$t('product.nav.applications'),
          name: 'product-apps',
          hide: () => this.product.source === productImporters.IMPORT_LIGHT,
          iconClass: 'icon-app-window-layout'
        }
      ].filter((link) => {
        return !link.hide || !link.hide();
      });
    },
    error() {
      return this.stats.error;
    },
    showRulesEditorToolbar() {
      return this.$router.currentRoute.name === 'product-rules-edit';
    }
  },
  methods: {
    ...mapActions('uiProductSearch', ['toggleSidebar']),
    ...mapActions({ fetchTests: 'productTests/fetch' }),
    toggleSearchSidebarVisibility() { },
    isQuestionnaire() {
      return [
        productTypes.QUESTIONNAIRE,
        productTypes.SHADOW_QUESTIONNAIRE
      ].includes(this.productType);
    },
    isProduct(product) {
      return [productTypes.PRODUCT, productTypes.SHADOW_PRODUCT].includes(
        product.type
      );
    },
    to(name, product, $route) {
      return {
        name,
        params: { productId: product.version.latest },
        query: $route.query
      };
    },
    hasError() {
      return !!this.stats.error;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

nav {
  background: $color-white;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(0, 0, 143, 0.1);
  border-top: 1px solid rgba(0, 0, 143, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  overflow-y: hidden;
  overflow-x: hidden;

  .gap-1 {
    gap: 4px;
  }

  .gap-6 {
    gap: 24px;
  }

  ul,
  ul li {
    margin: 0;
    padding: 0;
  }

  ul li {
    display: inline-block;

    svg {
      fill: currentColor;
      height: 24px;
      width: 24px;
    }
  }

  a {
    min-height: 45px;
    border-bottom: 2px solid transparent;
    margin-top: 3px;
  }

  a:hover {
    opacity: 1;
  }

  .router-link-active {
    border-bottom: 2px solid $color-orange-accent;
    display: block;
    font-weight: 600;
    color: $color-axa-blue;
  }

  .uppercase {
    text-transform: uppercase;
  }
}

.subtitle {
  font-size: 0.80em;
  font-weight: 600;
  line-height: 16px;

  .icon-check-circle-fill {
    color: $color-success;
  }
}

.beta-label {
  font-size: 10px;
  letter-spacing: 1px;
  margin-left: 2px;
}

.fontIcon {
  font-size: 125%;
}

.search-toolbar {
  margin-left: auto;
  color: $color-gray--action;

  .search-tab {
    height: 100%;

    .search-button {
      cursor: pointer;
      font-size: 14px;
      padding: 2px 4px;
    }

    &.on {
      border-bottom: 2px solid $color-orange-accent;

      .search-button {
        background-color: $color-active--20;
        color: $color-axa-blue;
        margin-top: 2px;
      }
    }
  }
}

.search-toolbar i {
  font-size: 125%;
  margin-left: 0.2em;
}

.loading-spinner {
  display: flex;
  align-items: center;
}
</style>
