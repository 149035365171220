export default {
  methods: {
    isValidName(name) {
      const specificationKeys = Object.values(this.product.specification)
        .map((spec) => Object.keys(spec))
        .flat();
      if (
        ['OUT', 'ASK', 'IN', 'Cover', ...specificationKeys]
          .map((k) => k.toUpperCase())
          .includes(name.trim().toUpperCase())
      ) {
        return false;
      }
      return true;
    },
    isRuleNameUnique(name) {
      for (let i = 0; i < this.product.rules.length; i += 1) {
        if (this.product.rules[i].name.toLowerCase() === name.trim().toLowerCase()) {
          return false;
        }
      }
      return true;
    },
    clearUndoStack(graph) {
      if ('undoStack' in graph) {
        // eslint-disable-next-line no-param-reassign
        delete graph.undoStack;
      }
      if (graph.children) {
        graph.children.forEach((child) => this.clearUndoStack(child));
      }
      return graph;
    },
    cleanRuleGraph(rule) {
      if (rule.graph) {
        rule.graph = this.clearUndoStack(rule.graph);
        if (rule.graph.terms) {
          rule.graph.terms = rule.graph.terms.filter(
            (term) => term.isRepresentation !== true
          );
        }
      }
      return rule;
    }
  }
};
