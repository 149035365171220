import { render, staticRenderFns } from "./ProductMetadata.vue?vue&type=template&id=464f2637&scoped=true"
import script from "./ProductMetadata.vue?vue&type=script&lang=js"
export * from "./ProductMetadata.vue?vue&type=script&lang=js"
import style0 from "./ProductMetadata.vue?vue&type=style&index=0&id=464f2637&prod&lang=scss"
import style1 from "./ProductMetadata.vue?vue&type=style&index=1&id=464f2637&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464f2637",
  null
  
)

export default component.exports