/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Vue from 'vue';
import './assets/styles/styles.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { sync } from 'vuex-router-sync';
import './plugins/element';
// eslint-disable-next-line import/no-extraneous-dependencies
import UiComponents from '@axa-getd/ui-components';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import router from './router';
import store from './store';
import { classNameForComponent } from './util';

import { i18n } from './i18n';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/pt';

import { setUnauthorizedCallback } from './api/helpers';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import "@axatechlab/claim-handler";

import App from './App.vue';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

const VueCookie = require('vue-cookie');

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(UiComponents);
Vue.use(VueCookie);

if (window.__env__.VUE_APP_BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: window.__env__.VUE_APP_BUGSNAG_KEY,
    appVersion: window.__env__.VUE_APP_HASH,
    appType: 'browser',
    releaseStage: window.__env__.VUE_APP_CLIENT_APP_URI.replace(
      'https://',
      ''
    ).replace('http://', ''),
    plugins: [new BugsnagPluginVue()]
  });
  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);
}

Vue.config.productionTip = false;
Vue.directive('autofocus', { inserted: (el) => el.focus() });

Vue.config.ignoredElements = ['claim-handler'];

/**
 * Add a v-selector directive to append the name of the component to the class
 * so that the component/view can be targeted more easily during testing
 */
Vue.directive('selector', {
  bind(el, binding, vnode) {
    const { name } = vnode.context.$options;
    if (name) {
      const prefix = binding.modifiers.view ? 'view' : 'component';
      el.classList.add(classNameForComponent(name, prefix));
    }
  }
});

sync(store, router);

setUnauthorizedCallback(() => router.push('/auth/login'));

const app = new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app');

export default app;
