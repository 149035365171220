<template>
  <div>
    <div class="d-flex tenantDataHeader p-2 el-row">
      <div>
        <el-row class="pb-2"><span>Team token</span></el-row>
        <el-row class="d-flex" width="30%"><el-input v-model="tokenName"
            :placeholder="$t('team.token-placeholder')"></el-input>
          <UiButton v-if="tenant && isAdmin(tenant.slug)" class="addButton" :data-cy="'team-generate-token'"
            @click="generateToken">
            {{ $t('team.generate-token') }}
          </UiButton>
        </el-row>
      </div>
    </div>
    <UiTable ref="table" :data="tokens" row-key="name" variant="padded">
      <!-- Name -->
      <el-table-column :label="$t('token.name')" prop="name" class-name="ui_token_name" :min-width="100">
      </el-table-column>

      <!-- Token -->
      <el-table-column :label="$t('token.token')" prop="hash" class-name="ui_token_name" :min-width="100">
      </el-table-column>

      <!-- Type -->
      <el-table-column :label="$t('team.token-type')" class-name="ui_token_modified" :min-width="75">
        <template slot-scope="scope">{{ tokenType(scope.row) }}</template>
      </el-table-column>

      <!-- Last Modified -->
      <el-table-column :label="$t('common.last_modified')" prop="last_used_at" class-name="ui_token_modified"
        :min-width="75">
        <template slot-scope="scope">{{ modified(scope.row) }}</template>
      </el-table-column>

      <!-- Operations -->
      <el-table-column width="180">
        <RowOperations slot-scope="{ $index }" :read-only="true" :is-read-only="false" :is-editable="false"
          class="ui_buttons_operations" @command="(command) => handleRowItemCommand(command, $index)" />
      </el-table-column>
    </UiTable>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import RowOperations from '../RowOperations.vue';
import BaseTokensMixin from '../BaseTokensMixin';
import TenantsTokensMixin from '../TenantsTokensMixin';

export default {
  name: 'TenantEditionTokens',
  components: { RowOperations },
  mixins: [BaseTokensMixin, TenantsTokensMixin],
  props: { tenant: { type: Object, default: undefined } },
  data: () => ({
    tokenName: '',
    tokenTypes: {
      1: 'Super Admin',
      2: 'Owner',
      3: 'Admin',
      4: 'Guest',
      5: 'Read-only (like Guest user)'
    }
  }),
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapState('tokens', ['tokens']),
  },
  mounted() {
    if (!this.tenant) {
      console.warn("TenantEditionTokens: tenant is not defined");
      return;
    }
    this.fetchTokens(this.tenant.slug);
  },
  methods: {
    ...mapActions('tokens', ['fetchTokens', 'createToken', 'deleteToken']),
    tokenType(token) {
      return this.tokenTypes[token.tenant_role_id];
    }
  }
};
</script>

<style lang="scss">
@import '@axatechlab/assets/scss/_variables';
</style>
