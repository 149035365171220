<template>
  <div class="d-flex flex-nowrap align-items-center">
    <span class="pr-3">
      {{ $t('impersonate') }}
    </span>
    <b-form-select v-model="selectedUser" right size="sm" :variant="selectedUser ? 'dark' : 'outline-dark'"
      boundary="viewport" class="impersonate-dropdown py-0">
      <b-form-select-option v-for="user of sortedUsers" :key="user.id" :value="user">
        {{ user.email }}
      </b-form-select-option>
    </b-form-select>
    <b-icon v-if="selectedUser" icon="x" @click.stop.capture.prevent="selectedUser = undefined" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ImpersonateDropdown',
  data() {
    return {
      isLoading: false,
      selectedUser: undefined
    };
  },
  computed: {
    ...mapState('users', ['error', 'users', 'roles']),
    sortedUsers() {
      if (this.users && this.users.length) {
        return [...this.users].sort((a, b) => (a.email < b.email ? -1 : 1));
      }
      return [];
    }
  },
  watch: {
    users() {
      if (this.users && this.users.length) {
        if (localStorage && localStorage.impersonate !== undefined) {
          this.selectedUser = this.users.find(
            // eslint-disable-next-line eqeqeq
            (u) => u.id == localStorage.impersonate
          );
        }
      }
    },
    async selectedUser() {
      if (this.selectedUser) {
        if (localStorage.impersonate != this.selectedUser.id) {
          localStorage.impersonate = this.selectedUser.id;
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        } else {
          await this.getImpersonatedUser(this.selectedUser.id);
        }
      } else {
        localStorage.removeItem('impersonate');
        this.unsetImpersonatedUser();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions('users', [
      'getUsers',
      'getImpersonatedUser',
      'getRoles',
      'setError',
      'unsetImpersonatedUser'
    ]),
    ...mapActions('teams', ['loadTeams']),
    async fetchUsers() {
      this.isLoading = true;
      await this.getUsers();
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import '@axatechlab/assets/scss/_variables';

.impersonate-dropdown {
  color: $color-gray--action;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  background: #ffffff00 url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e') right 20px center/8px 10px no-repeat;
  text-transform: uppercase;
  max-width: 120px;
  text-overflow: ellipsis;
  max-height: 32px;

  .dropdown-toggle {
    border: none;
  }

  .dropdown-menu {
    position: fixed !important;
    max-height: 600px;
    overflow-y: auto;
  }
}
</style>