/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable consistent-return */
import feathersClient from '@/feathers';

export const types = {
  GET_USERS: 'GET_USERS',
  GET_ROLES: 'GET_ROLES',
  SET_ERROR: 'SET_ERROR',
  SET_LOADING: 'SET_LOADING'
};

const getUsers = async ({ commit }) => {
  commit(types.SET_ERROR, null);
  commit(types.SET_LOADING, true);
  try {
    const users = await feathersClient.service('users').find();
    commit(types.GET_USERS, users);
  } catch (err) {
    if (err.code === 401) {
      commit(types.SET_ERROR, 'Session token is invalid or has expired');
    } else {
      commit(types.SET_ERROR, err.message);
    }
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const getImpersonatedUser = async ({ commit }, userId) => {
  try {
    const user = await feathersClient.service('users').get(userId);
    commit('auth/SET_IMPERSONATED_USER', user, { root: true });
  } catch (err) {
    if (err.code === 401) {
      commit(types.SET_ERROR, 'Session token is invalid or has expired');
    } else {
      commit(types.SET_ERROR, err.message);
    }
  }
};

const unsetImpersonatedUser = async ({ commit }) => {
  commit('auth/SET_IMPERSONATED_USER', undefined, { root: true });
};

const updateUserPreferences = async ({ commit, rootState }, { id, data }) => {
  try {
    const user = await feathersClient
      .service('users')
      .update(id, {
        email: rootState.auth._user.email,
        verified: rootState.auth._user.verified,
        type: rootState.auth._user.type,
        preferences: data
      });
    commit('auth/SET_USER', user, { root: true });
  } catch (err) {
    if (err.code === 401) {
      commit(types.SET_ERROR, 'Session token is invalid or has expired');
    } else {
      commit(types.SET_ERROR, err.message);
    }
  }
};

const getOrphanUsers = async ({ commit }) => {
  commit(types.SET_LOADING, true);
  try {
    const usersToRestore = await feathersClient.service('users').find({
      query: {
        unlink: 1
      }
    });
    const aWeekAgo = new Date();
    aWeekAgo.setDate(aWeekAgo.getDate() - 7);
    return usersToRestore.filter((u) => {
      const userModified = new Date(u.updated_at);
      return aWeekAgo < userModified;
    });
  } catch (err) {
    console.error(err);
  } finally {
    commit(types.SET_LOADING, false);
  }
}

const getUsersByTenant = async ({ commit }, { tenantSlug }) => {
  commit(types.SET_ERROR, null);
  commit(types.SET_LOADING, true);
  try {
    return await feathersClient.service('users').find({
      query: {
        tenant: tenantSlug
      }
    });
  } catch (err) {
    if (err.code === 401) {
      commit(types.SET_ERROR, 'Session token is invalid or has expired');
    } else {
      commit(types.SET_ERROR, err.message);
    }
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const getRoles = async ({ commit }) => {
  commit(types.SET_ERROR, null);
  commit(types.SET_LOADING, true);
  try {
    const roles = await feathersClient.service('roles').find();
    commit(types.GET_ROLES, roles);
  } catch (err) {
    if (err.code === 401) {
      commit(types.SET_ERROR, 'Session token is invalid or has expired');
    } else {
      commit(types.SET_ERROR, err.message);
    }
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const updateUser = async ({ commit, rootState }, { id, data }) => {
  commit(types.SET_ERROR, null);
  commit(types.SET_LOADING, true);
  try {
    const user = await feathersClient.service('users').update(id, data);
    await getUsers({ commit });
    if (rootState.auth.user.id === id) {
      commit('auth/SET_USER', user, { root: true });
    }
  } catch (err) {
    if (err.code === 401) {
      commit(types.SET_ERROR, 'Session token is invalid or has expired');
    } else {
      commit(types.SET_ERROR, err.message);
    }
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const deleteUser = async ({ commit }, { id, data }) => {
  commit(types.SET_ERROR, null);
  commit(types.SET_LOADING, true);
  try {
    await feathersClient.service('users').remove(id, data);
    await getUsers({ commit });
  } catch (err) {
    if (err.code === 401) {
      commit(types.SET_ERROR, 'Session token is invalid or has expired');
    } else {
      commit(types.SET_ERROR, err.message);
    }
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const setError = ({ commit }, error) => {
  commit(types.SET_ERROR, error);
};

const actions = {
  getUsers,
  getImpersonatedUser,
  unsetImpersonatedUser,
  getOrphanUsers,
  getUsersByTenant,
  getRoles,
  setError,
  updateUser,
  updateUserPreferences,
  deleteUser
};

export default actions;
