var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{directives:[{name:"selector",rawName:"v-selector"}],staticClass:"px-5"},[_c('ul',{staticClass:"d-flex gap-6"},[_vm._l((_vm.links),function({
      name,
      label,
      iconClass,
      isBeta,
      tests = false,
      exact = false
    }){return _c('li',{key:name,staticClass:"d-flex align-items-stretch"},[(!_vm.hasError() || name !== 'product-tests')?_c('router-link',{staticClass:"d-flex flex-column align-items-center justify-content-center flex-xl-row d-xl-inline-flex py-0",class:'ui_link_' + name,attrs:{"to":_vm.to(name, _vm.product, _vm.$route),"exact":exact,"data-cy":'tab-' + name}},[(iconClass)?_c('div',{class:'fontIcon d-lg-none d-block mr-lg-2 ' + iconClass}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"label d-none d-lg-flex align-items-center justify-content-center gap-1",attrs:{"data-cy":"product-tab-name"}},[_c('div',{staticClass:"d-flex uppercase"},[_vm._v("\n            "+_vm._s(label)+"\n            "),(isBeta)?_c('span',{staticClass:"beta-label"},[_vm._v("BETA")]):_vm._e()]),_vm._v(" "),(tests)?_c('div',{staticClass:"d-none d-xl-block",class:tests.success === tests.total
            ? 'subtitle'
            : 'subtitle text-danger'},[(
              !_vm.hasError() &&
              !_vm.stats.isLoading &&
              _vm.stats.progress === undefined
            )?_c('div',{staticClass:"fontIcon",class:!tests.total ? '' : (tests.success === tests.total
              ? 'icon-check-circle-fill'
              : 'icon-alert-circle')}):(_vm.hasError())?_c('div',{staticClass:"text-danger el-icon-refresh",on:{"click":function($event){$event.stopPropagation();return _vm.fetchTests()}}},[_vm._v("\n              Error!\n            ")]):_c('div',{directives:[{name:"selector",rawName:"v-selector"}],staticClass:"loading-spinner is-active"},[_c('b-icon',{attrs:{"icon":"arrow-clockwise","animation":"spin","font-scale":"1"}})],1)]):_vm._e()])]):_c('a',{staticClass:"d-flex flex-column align-items-center flex-xl-row d-xl-inline-flex py-0",class:'ui_link_' + name,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.fetchTests()}}},[(iconClass)?_c('div',{class:'fontIcon mr-lg-2 ' + iconClass}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"label d-none d-lg-block",attrs:{"data-cy":"product-tab-name"}},[_c('div',{staticClass:"d-flex uppercase"},[_vm._v("\n            "+_vm._s(label)+"\n            "),(isBeta)?_c('span',{staticClass:"beta-label"},[_vm._v("BETA")]):_vm._e()]),_vm._v(" "),(_vm.subtitle)?_c('div',{staticClass:"subtitle d-none d-xl-block"},[_vm._v("\n            "+_vm._s(_vm.subtitle)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-none d-xl-block subtitle text-danger"},[_c('div',{staticClass:"text-danger el-icon-refresh"},[_vm._v("Error!")])])])])],1)}),_vm._v(" "),_c('div',{staticClass:"search-toolbar d-flex align-items-center"},[_c('div',{class:'search-tab d-flex align-items-center' + (_vm.searchSidebarVisible ? ' on' : '')},[_c('div',{class:'d-flex gap-1 align-items-center uppercase search-button' + (_vm.searchSidebarVisible ? ' on' : ''),on:{"click":_vm.toggleSidebar}},[_c('i',{staticClass:"icon-search"}),_vm._v("\n          "+_vm._s(_vm.$t('common.search'))+"\n        ")])])]),_vm._v(" "),_c('rules-editor-toolbar',{key:'tb-' + _vm.$route.path,attrs:{"visible":_vm.showRulesEditorToolbar,"read-only":_vm.isReadOnly,"rule-id":parseInt(_vm.$router.currentRoute.params.ruleId)}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }