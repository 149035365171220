<template>
  <div class="ProductMetadata">
    <StickyNotification />
    <UiLoadingArea :is-loading="isLoading">
      <CreationDialog :visible="isCreationDialogVisible" :to-valid-name="toValidName" @create="handleCreate"
        @cancel="hideCreationDialog" @close="hideCreationDialog" />
      <ProductConfigurationTable property="metadata" :properties="propertiesSorted" :sort-field="false"
        :sort-order="false" @dblclick="(row) => editRow(propertiesSorted.indexOf(row))" @sort-change="onSort">
        <TableColumnName :label="$t('product.name')" :name="editedRow.value ? editedRow.value.name : undefined"
          :color="editedRow.value ? editedRow.value.color : undefined" :colors="colors" :product="product"
          :edit-row="editRow" :display-name="displayName" :display-name-only="isSystemMetadata" :is-editable="(r) =>
            isEditable(r) &&
            (r.value && r.value.name ? !r.value.name.startsWith('_') : true)
            " :is-editing="isEditing" :save-item="handleSave" :has-color="true"
          @color-change="(v) => (editedRow.value.color = v)" @change="(v) => {
            return filterName(editedRow.value, 'name', v);
          }
            ">
          <template #icons="{ row }">
            <UiIconTip v-if="isSharedMetadata(row)" icon="el-icon-question"
              message="The value of this metadata is shared across all releases." />
            <UiIconTip v-if="hasHelpMessage(row)" icon="el-icon-setting" :message="getHelpMessage(row)" />
          </template>
        </TableColumnName>

        <el-table-column prop="type" :label="$t('product.type')" width="130" data-cy="property-type">
          <template #default="{ row, $index }">
            <el-select v-if="isEditing($index) &&
              isEditable(row, $index) &&
              !row.name.startsWith('_')
            " v-model="editedRow.value.type" :data-cy="`property-type-${row.name}`" data-test="property-type"
              placeholder="Type" class="ui_item_type" @change="handleTypeChange">
              <el-option v-for="type in types" :key="type" :value="type" :label="translateValueType(type)" />
            </el-select>
            <span v-else class="ui_item_type">
              {{ row.type ? translateValueType(row.type) : null }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="value" :label="$t('product.metadataValue')" data-test="property-metadata-value">
          <template #default="{ row, $index }">
            <div class="ValueColumn">
              <div v-if="row.name === 'team'">
                <UiInlineDropDown :id="`editable-${row.name}`" :value="row.value.id" :toggle-editing="isEditing($index)"
                  :items="availableTeamsOptions" :handle-change="handleValueChange">
                  <template #label>{{ row.value.label }}</template>
                </UiInlineDropDown>
              </div>
              <div v-else-if="row.name === '_defaultLanguage'">
                <UiInlineDropDown :id="`editable-${row.name}`" :value="row.value" :toggle-editing="isEditing($index)"
                  :items="availableLanguages" :handle-change="handleValueChange">
                  <template #label>{{ languageLabel(row.value) }}</template>
                </UiInlineDropDown>
              </div>
              <div v-else-if="row.name === '_technicalName'">
                <UiUriSegmentInput v-if="isEditing($index)" :id="`editable-${row.name}`" :value="row.value"
                  @change="handleValueChange" />
                <div v-else>
                  <div v-if="row.value">{{ row.value }}</div>
                  <div v-else><em class="Emphasis">Empty</em></div>
                </div>
              </div>
              <div v-else-if="row.name === '_productName' || row.name === '_productAuthor'
              ">
                <UiInputWithConstraint v-if="isEditing($index)" :id="`editable-${row.name}`" :value="row.value"
                  :constraint="/^[\w)(\s-]+$/" @change="handleValueChange" />
                <div v-else>
                  <div v-if="row.value">{{ row.value }}</div>
                  <div v-else><em class="Emphasis">Empty</em></div>
                </div>
              </div>
              <div v-else-if="row.type === 'Boolean'">
                <UiToggle v-if="isEditing($index)" :id="`editable-${row.name}`" :value="!!row.value"
                  @change="handleValueChange" />
                <div v-else>
                  {{ row.value ? 'Yes' : 'No' }}
                </div>
              </div>
              <div v-else-if="row.type === 'DateTime'">
                <UiDateInput v-if="isEditing($index)" :id="`editable-${row.name}`" variant="DateTime" :value="row.value"
                  @change="handleValueChange" />
                <div v-else>
                  <UiFormattedDate v-if="row.value" :date="row.value" format="DD-MM-YYYY H:m" />
                  <div v-else><em>Empty</em></div>
                </div>
              </div>
              <div v-else-if="row.type === 'Date'">
                <UiDateInput v-if="isEditing($index)" :id="`editable-${row.name}`" variant="Date" :value="row.value"
                  @change="handleValueChange" />
                <div v-else>
                  <UiFormattedDate v-if="row.value" :date="row.value" format="DD-MM-YYYY" />
                  <div v-else><em>Empty</em></div>
                </div>
              </div>
              <UiInlineEditable v-else :id="`editable-${row.name}`" :label="row.value" :value="row.value"
                :toggle-editing="isEditing($index)" :handle-change="handleValueChange" @save="handleSave($index)" />
            </div>
          </template>
        </el-table-column>

        <el-table-column width="180">
          <RowOperations slot-scope="{ $index, row }" :is-read-only="isReadOnlyMode" :is-editing="isEditing($index)"
            :is-inherited="row.protected" class="ui_buttons_operations"
            @command="(command) => handleRowItemCommand(command, $index)" />
        </el-table-column>
      </ProductConfigurationTable>
      <ui-pagination v-if="propertiesSorted.length" ref="paginator" :key="Date.now()" :current-page.sync="page"
        :page-size="count" :total="properties.length" />

    </UiLoadingArea>
  </div>
</template>

<script>
import { Message, MessageBox } from 'element-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import { noop } from '../../util';
import { productTypes } from '../../const/product';
import { sortByFieldname } from '../../helpers';
import ProductSaveMixin from '../ProductSaveMixin';
import PropertiesMixin from '../PropertiesMixin';
import CreationDialog from './Configuration/CreationDialog.vue';
import ProductConfigurationTable from './Configuration/ProductConfigurationTable.vue';
import TableColumnName from './Configuration/TableColumnName.vue';
import RowOperations from '../RowOperations.vue';
import { availableLanguages } from '../../helpers/translations';
import StickyNotification from '../StickyNotification';
import UiPagination from '@axa-getd/ui-components/src/components/UiPagination/UiPagination.vue';

const sharedMetadata = ['_defaultLanguage', '_technicalName', '_productAuthor'];
const helpMessage = {
  _isFallbackRelease:
    'Maximum one fallback release is allowed across all releases'
};

export default {
  name: 'ProductMetadata',
  components: {
    CreationDialog,
    ProductConfigurationTable,
    TableColumnName,
    RowOperations,
    StickyNotification,
    UiPagination
  },
  mixins: [ProductSaveMixin, PropertiesMixin],
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      columns: ['name', 'type', 'value'],
      page: 1,
      count: 10,
      sortField: 'name',
      sortOrder: 'ascending',
      properties: [],
      propertiesSorted: [],
      previousSearch: '',
      types: ['Number', 'Date', 'DateTime', 'Boolean', 'Text'],
      availableLanguages,
      team: null
    };
  },
  computed: {
    ...mapGetters('product', ['isReadOnly']),
    ...mapState('productProperty', {
      isProductPropertyLoading: 'isLoading',
      allProperties: 'properties'
    }),
    ...mapGetters('auth', ['isGuest', 'isMember', 'isOwner']),
    ...mapGetters('productProperty', ['metadata']),
    ...mapState('productTemplateTypes', { templates: 'templateTypes' }),
    ...mapState('teams', ['teams']),
    ...mapState('auth', ['tenant']),
    isQuestionnaire() {
      return productTypes.QUESTIONNAIRE === this.product.type;
    },
    isTemplate() {
      return productTypes.TEMPLATE === this.product.type;
    },
    isProduct() {
      return productTypes.PRODUCT === this.product.type;
    },
    isLoading: {
      get() {
        return (
          this.isProductPropertyLoading && this.propertiesSorted.length === 0
        );
      }
    },
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    isShadowProduct() {
      return productTypes.SHADOW_PRODUCT === this.product.type;
    },
    isShadowQuestionnaire() {
      return productTypes.SHADOW_QUESTIONNAIRE === this.product.type;
    },
    defaultTeam() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.find((t) => t.slug === this.tenant)
      );
    },
    availableTeams() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.filter(
          (t) =>
            t.tenant === this.tenant &&
            (this.isMember(t.slug) || this.isOwner(this.defaultTeam.slug))
        )
      );
    },
    availableTeamsOptions() {
      if (this.availableTeams)
        return this.availableTeams.map((t) => {
          return { key: t.id, label: t.label };
        });

      return [];
    }
  },
  watch: {
    page() {
      this.stopEditing();
      this.updatePaginatedProperties();
    },
    metadata() {
      if (this.metadata.length) {
        this.metadata.push({
          metadata: {
            value: this.product.team
          },
          name: 'team',
          value: this.product.team,
          protected: true
        });
        this.initializeProperties();
      }
    }
  },
  async mounted() {
    this.page = 1;
    this.initializeProperties();
    this.updatePaginatedProperties();
    this.team = this.product.team.id;
  },
  methods: {
    ...mapActions('product', [
      'attachProductMetadata',
      'removeProductMetadata',
      'configureProductMetadata',
      'markAsTemplate',
      'markAsProduct',
      'setTeam'
    ]),
    handleValueChange(value) {
      this.editedRow.value.value = value;
    },
    async handleTypeChange() {
      this.editedRow.value.value = null;
      const data = this.editedRow.value;
      const previousData = this.propertiesSorted[this.editedRow.index];
      await this.configureProductMetadata({
        color: data.color,
        name: data.name,
        previousName: previousData.name,
        type: data.type,
        value: data.value,
        withNotification: false
      });
    },
    languageLabel(key) {
      return (
        (this.availableLanguages.find((lang) => lang.key === key) || {})
          .label || ''
      );
    },
    initializeProperties() {
      this.properties = this.metadata;
      if (this.previousSearch !== this.search) {
        this.page = 1;
        this.previousSearch = this.search;
      }
      this.updatePaginatedProperties();
    },
    isEditingAllowed(row) {
      return this.isEditable(row) || row.name === '_locked';
    },
    isEditingBlocked(row) {
      return !this.isEditingAllowed(row);
    },
    isEditable(row) {
      if (row.protected) return false;
      return !this.isReadOnlyMode;
    },
    handleRowItemCommand(command, index) {
      if (!this.isReadOnlyMode) {
        switch (command) {
          case 'edit':
            this.editRow(index);
            break;
          case 'save':
            this.handleSave(index);
            break;
          case 'delete':
            this.handleDelete(index);
            break;
          case 'cancel':
            this.stopEditing();
            break;
          default:
            throw new Error(`Unknown command ${command}`);
        }
      }
    },
    isSystemMetadata(row) {
      return row.name.startsWith('_');
    },
    isSharedMetadata(row) {
      return sharedMetadata.includes(row.name);
    },
    hasHelpMessage(row) {
      return Object.keys(helpMessage).includes(row.name);
    },
    getHelpMessage(row) {
      return helpMessage[row.name];
    },
    displayName(row) {
      if (!this.isSystemMetadata(row)) return null;
      return (
        this.$t(`product.protectedMetadata.${row.name}`) || row.name.trim()
      );
    },
    editRow(index) {
      const entry = this.propertiesSorted[index];
      if (this.isReadOnlyMode || this.editedRow.index === index) return;
      this.editedRow = {
        index,
        value: {
          ...entry,
          color: this.colors[entry.color]
            ? this.colors[entry.color]
            : entry.color
        }
      };
    },
    async handleCreate(data) {
      this.stopEditing();
      this.hideCreationDialog();
      if (this.propertyExists(data.name)) {
        Message.error(this.$t(`product.prop_name_exists`));
      } else {
        await this.attachProductMetadata({ name: data.name, type: 'Text' });
      }
    },
    async handleSave(index) {
      const data = this.editedRow.value;
      if (data.name === 'team') {
        await this.setTeam({ team: data.value });
        this.propertiesSorted[index].value = this.availableTeams.find(
          (t) => t.id === data.value
        );
        this.stopEditing();
      } else {
        const previousData = this.propertiesSorted[index];
        this.stopEditing();
        if (data.name !== previousData.name && this.propertyExists(data.name)) {
          Message.error(this.$t(`product.prop_name_exists`));
        } else {
          try {
            await this.configureProductMetadata({
              color: data.color,
              name: data.name,
              previousName: previousData.name,
              type: data.type,
              value: data.value
            });
          } catch (err) {
            Message.error(err.errorDescription);
          }
        }
      }
    },
    async handleDelete(index) {
      this.stopEditing();
      const data = this.propertiesSorted[index];

      const confirmMsg = this.$t('product.confirm.delete', { name: data.name });
      MessageBox.confirm(confirmMsg)
        .then(async () => {
          await this.removeProductMetadata({ name: data.name });
        })
        .catch(noop);
    },
    stopEditing() {
      this.editedRow = { index: null, value: null };
    },
    updatePaginatedProperties() {
      let properties = sortByFieldname(
        this.properties,
        this.sortField,
        this.sortOrder
      );
      properties = properties.filter((p) => p.name !== '_locked');

      properties = properties.slice(
        (this.page - 1) * this.count,
        (this.page - 1) * this.count + this.count
      );

      this.propertiesSorted = properties;
    },
    onUpdateIsTemplate(value) {
      if (value.isTemplate) {
        this.markAsTemplate({ templateTypeId: value.templateTypeId });
      } else {
        this.markAsProduct({});
      }
    },
    propertyExists(name) {
      return (
        this.allProperties.filter(
          (p) => p.name.toLowerCase() === name.toLowerCase()
        ).length > 0
      );
    }
  }
};
</script>

<style lang="scss">
.ValueColumn .el-select .el-input__inner {
  border: none;
  padding: 0;
}
</style>
<style lang="scss" scoped>
.ValueColumn {
  width: 100%;
}

.Emphasis {
  opacity: 0.5;
}

.el-select {
  width: 100%;
}
</style>
