<template>
  <div class="d-flex flex-column flex-grow-1 overflow-auto">
    <UiPaddedArea class="p-0">
      <UiToolbar>
        <div class="flex-spacer" />
        <b-input-group class="search-group">
          <b-form-input v-model="searchStr" class="search-input" data-cy="tests-search"
            :placeholder="$t(`team.search-tenant`)" />
          <b-icon icon="search" class="search-input-icon"></b-icon>
        </b-input-group>
        <div class="flex-spacer" />
        <b-button v-if="isSuperAdmin()" variant="primary" @click="showCreateTenantDialog">
          <i class="fas fa-plus mr-2"></i>{{ $t('user.create_tenant') }}
        </b-button>
      </UiToolbar>
    </UiPaddedArea>

    <UiLoadingArea :is-loading="isLoading" class="px-2">
      <div v-if="!isLoading">
        <transition v-enter-active mode="out-in" class="animated fadeIn">
          <TenantTable :teams="allTenants || []" :fetch-tenants="fetchTenants"
            :on-edit="(tenant) => editTenant(tenant)"></TenantTable>
        </transition>
        <TenantEditionModal :tenant="editedTenant" :visible="showTenantEditionDialog"
          @close="(success) => finishedEdition(success)" />
        <TenantCreationModal :visible="showTenantCreationDialog" @close="finishedCreation" />
      </div>
    </UiLoadingArea>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { MessageBox } from 'element-ui';
import TenantEditionModal from './TenantEditionModal.vue';
import TenantCreationModal from './TenantCreationModal.vue';
import TenantTable from './TenantTable';

export default {
  name: 'TenantsIndex',
  components: {
    TenantCreationModal,
    TenantEditionModal,
    TenantTable
  },
  data: () => ({
    searchStr: null,
    allTenants: null,
    showTenantCreationDialog: false,
    showTenantEditionDialog: false,
    editedTenant: null
  }),
  computed: {
    ...mapState('teams', ['tenants', 'isLoading', 'error']),
    ...mapGetters('auth', ['isSuperAdmin'])
  },
  watch: {
    searchStr: {
      handler(val) {
        const str = val.toLowerCase();
        this.allTenants = this.tenants.filter((tenant) => {
          return tenant.label.toLowerCase().includes(str);
        });
      }
    },
    error: {
      immediate: true,
      async handler(error) {
        if (error) {
          this.setError(undefined);
          this.$router.push({ name: 'product-home' });
        }
      }
    }
  },
  async mounted() {
    await this.fetchTeams();
    await this.fetchTenants();
    await this.getUsers();
    this.showTenantCreationDialog = false;
    this.showTenantEditionDialog = false;
    const { tenant: tenantSlug } = this.$route.query;
    if (tenantSlug) {
      const tenant = this.allTenants.find((t) => t.slug === tenantSlug);
      if (!tenant) {
        MessageBox.alert(this.$t('incorrect-tenant-slug'));
      } else {
        this.editTenant(tenant);
      }
    }
  },
  methods: {
    ...mapActions('teams', ['loadTenants', 'loadTeams', 'setError']),
    ...mapActions('users', ['getUsers']),
    async fetchTeams() {
      if (!this.teams) await this.loadTeams({ includeUsers: true });
    },
    finishedCreation() {
      this.showTenantCreationDialog = false;
      this.fetchTenants();
      this.loadTeams({ includeUsers: true });
    },
    finishedEdition() {
      this.showTenantEditionDialog = false;
      this.editedTenant = null;
      this.fetchTenants();
      this.loadTeams({ includeUsers: true });
    },
    async fetchTenants() {
      await this.loadTenants();
      this.allTenants = this.tenants;
    },
    showCreateTenantDialog() {
      this.showTenantCreationDialog = true;
    },
    showEditTeamDialog() {
      this.showTenantEditionDialog = true;
    },
    editTenant(tenant) {
      this.editedTenant = tenant;
      this.showEditTeamDialog();
    }
  }
};
</script>
