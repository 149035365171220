<template>
  <div>
    <UiTable :data="usersToShow" :default-sort="{ prop: 'updated_at', order: 'descending' }" class="ui_table_users">
      <el-table-column :label="$t('auth.username')" prop="email" sortable :sort-method="sortByName">
        <template slot-scope="scope">
          <div v-if="scope.row.name" class="avatar-circle" :style="{ background: colorForName(scope.row.name) }">
            <span class="avatar-letters">{{
              initialsFromName(scope.row.name)
            }}</span>
          </div>
          <div class="d-flex flex-column">
            <div>{{ scope.row.name }}</div>
            <div>{{ scope.row.email }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('user.role_name')" prop="role_name">
        <template slot-scope="scope">
          <div v-if="roles.length" class="roles d-flex flex-wrap">
            <RoleTag v-for="(team, index) of scope.row.teams" :key="index" :data="{ teamId: scope.row.id }"
              :left="team.label" :right="userRoleLabelById(scope.row, team.id)" :left-condition="() => userRoleLabelById(scope.row, team.id) !== 'Super Admin'
                " :delete-enabled="canDeleteMember(scope.row, team)"
              @delete="deleteRole(scope.row, team.tenant, team.id)" />
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="isSuperAdmin()" label="SuperAdmin" :min-width="30">
        <template slot-scope="scope">
          <el-switch v-model="superAdmins[scope.row.email]" :disabled="scope.row.id === user.id"
            @change="setSuperAdmin(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.last_modified')" sortable :sort-method="sortUpdatedAt"
        class-name="ui_user_modified" :min-width="40">
        <template slot-scope="{ row }">
          <UiFormattedDate :date="getUpdatedAt(row)" />
        </template>
      </el-table-column>
      <el-table-column v-if="isSuperAdmin()" :min-width="10">
        <template slot-scope="{ row }">
          <UiThreeDotsDropDown :items="dropdownItems(row.id)"
            @command="(command) => handleDropdownCommand(command, row)" />
        </template>
      </el-table-column>
    </UiTable>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { MessageBox } from 'element-ui';
import RoleTag from './RoleTag';

export default {
  name: 'UserTable',
  components: {
    RoleTag
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    teams: {
      type: Array,
      required: true
    },
    fetchUsers: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    superAdmins: {}
  }),
  computed: {
    ...mapGetters('auth', ['isOwner', 'isSuperAdmin']),
    ...mapGetters('auth', { user: 'getUser' }),
    usersToShow() {
      return this.users.map((u) => (u.id === this.user.id ? this.user : u));
    }
  },
  mounted() {
    this.users.forEach((user) => {
      Vue.set(this.superAdmins, user.email, this.isSuperAdmin(user));
    });
  },
  methods: {
    ...mapActions('users', ['updateUser', 'getUsersByTenant', 'deleteUser']),
    ...mapActions('teams', ['saveTenant']),
    sortByName(a, b) {
      return a.email.localeCompare(b.email);
    },
    userRoleById(user, teamId) {
      const team = user.teams.find((userTeam) => userTeam.id === teamId);
      return team ? this.roleById(team.UserTeam.team_role_id) : {};
    },
    userRoleLabelById(user, teamId) {
      const role = this.userRoleById(user, teamId);
      return role ? role.label : '';
    },
    roleById(roleId) {
      return this.roles.find((role) => role.id === roleId);
    },
    colorForName(name, saturation = 30, lightness = 80) {
      if (!name) return 'transparent';

      let hash = 0;
      for (let i = 0; i < name.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }

      const h = hash % 360;
      return `hsl(${h}, ${saturation}%, ${lightness}%)`;
    },
    initialsFromName(name) {
      return name
        ? name
          .replace(/[^a-zA-Z- ]/g, '')
          .match(/\b\w/g)
          .join('')
        : '';
    },
    async deleteRole(row, tenant, teamId) {
      const { id: userId } = row;
      try {
        await MessageBox.confirm(
          this.$t('user.msg-user-removed-tenant', {
            tenant
          })
        );

        const teamUsers = await this.getUsersByTenant({
          tenantSlug: tenant
        });

        const newUsers = teamUsers.map((user) => {
          return {
            ...user,
            user_id: user.id,
            tenant_role_id: this.userRoleById(user, tenant.id).id,
            teams:
              user.id === userId
                ? user.teams
                  .filter((t) => t.id !== teamId)
                  .map((t) => t.UserTeam)
                : user.teams.map((t) => t.UserTeam)
          };
        });

        await this.saveTenant({
          data: { slug: tenant, users: newUsers }
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async setSuperAdmin(modifiedUser) {
      try {
        const newRole = this.superAdmins[modifiedUser.email] ? 1 : 5;
        const team = modifiedUser.teams[0];
        const teamUsers = await this.getUsersByTenant({
          tenantSlug: team.tenant
        });
        const modifiedUsers = teamUsers.map((u) => {
          return {
            user_id: u.id,
            teams: u.teams.map((t) => ({
              team_id: t.UserTeam.team_id,
              team_role_id:
                u.id === modifiedUser.id
                  ? newRole
                  : this.userRoleById(u, team.id).id,
              tenant_role_id:
                u.id === modifiedUser.id
                  ? newRole
                  : this.userRoleById(u, team.id).id
            }))
          };
        });

        await this.saveTenant({
          data: { slug: team.tenant, users: modifiedUsers }
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    canDeleteMember(member, team) {
      const hasTeamPrivileges = this.isSuperAdmin() || this.isOwner(team.id);
      const isMemberSuperAdmin = member.teams.some(
        (g) => g.UserTeam.tenant_role_id === 1
      );
      const isCurrentUser = member.email === this.user.email;
      return hasTeamPrivileges && !isCurrentUser && !isMemberSuperAdmin;
    },
    getUpdatedAt(user) {
      // Here we display the updated_at date of the most recently updated team
      const allDates = user.teams.map((g) => g.updated_at);
      return allDates.sort().reverse()[0] || user.updated_at;
    },
    sortUpdatedAt(a, b) {
      const aDate = this.getUpdatedAt(a);
      const bDate = this.getUpdatedAt(b);

      if (aDate > bDate) {
        return 1;
      }
      if (aDate < bDate) {
        return -1;
      }
      return 0;
    },
    handleDropdownCommand(command, user) {
      switch (command) {
        case 'delete':
          this.confirmDeleteUser(user);
          break;
        default:
          throw new Error(`Unknown command ${command}`);
      }
    },
    dropdownItems() {
      return [
        {
          command: 'delete',
          label: this.$t('action.delete'),
          class: 'ui_menu_delete',
          icon: 'far fa-trash-alt',
          variant: 'danger'
        }
      ];
    },
    async confirmDeleteUser(user) {
      try {
        await MessageBox.confirm(
          this.$t('user.msg-confirm-delete-user', {
            label: user.email
          }),
          this.$t('definition-lists.msg-confirm-deletion')
        );
        try {
          await this.deleteUser({ id: user.id });
          await this.fetchUsers();
        } catch (err) {
          let message = this.$t('team.delete_error');
          if (err.response && err.response.data) {
            message = err.response.data.error;
          }
          MessageBox.alert(message, this.$t('team.delete_failed'));
        }
        // eslint-disable-next-line no-empty
      } catch { }
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.avatar-circle {
  width: 44px;
  height: 44px;
  background-color: #5e77ff;
  text-align: center;
  border-radius: 50%;
  display: table;
}

.avatar-letters {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
  color: #ffffff;
}
</style>
