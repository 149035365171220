<template>
  <div>
    <product-export-manager v-if="product" :product-id="product.version.latest" :visible="showProductExportManager"
      :current-export-properties="exportProperties" @close="(success) => closeExportWizard()"></product-export-manager>
    <header v-selector class="d-flex align-items-center px-2">
      <b-button variant="light" @click="toggleMainMenu" class="menu-button">
        <b-icon v-if="menuOpen" icon="x" />
        <b-icon v-else icon="list" />
        {{ $t('menu') }}
      </b-button>
      <div v-if="isLoggedIn" class="d-flex align-items-center">
        <span v-if="showProductName" class="ProductRootline">
          <span class="ProductRootline__Breadcrumb">
            <span v-if="'TEMPLATE' === product.type" class="cartridge">TEMPLATE</span>
            <span v-if="product.entityName" class="EntityName text-nowrap ml-2">
              {{ product.entityName }}
            </span>
            <b-icon icon="chevron-right" font-scale="0.8" class="ml-2" />
            <span class="ProductName">
              <VersionDropdown :product-name="product.name" :current-version="product.currentVersion">
                <ReadOnlyLabel class="ProductName__Status" :readonly="isReadOnlyMode" :locked="product.locked" />
              </VersionDropdown>
            </span>
          </span>
          <el-button v-if="product" class="ui_button_download" title="Download" size="mini" type="text"
            icon="ml-2 fas fa-download" @click="exportProduct" />
        </span>
      </div>

      <div class="ml-auto">
        <transition enter-active-class="animated fadeIn" mode="out-in" appear>
          <el-popover v-if="isLoggedIn && announcements.length" placement="bottom" title="Notifications" width="200"
            trigger="hover">
            <el-badge slot="reference" :value="announcements.length" class="item mr-4">
              <el-button circle size="mini">
                <BellIcon class="icon" />
              </el-button>
            </el-badge>
            <div>
              Latest Announcements
              <ul>
                <li v-for="announcement in announcements" :key="announcement">
                  {{ announcement }}
                </li>
              </ul>
            </div>
          </el-popover>
        </transition>
      </div>
    </header>
    <div v-if="settings && settings.displayMessage" class="maintenance" :class="{
      modeON: settings.maintenance,
      modeOFF: !settings.maintenance
    }">
      {{ settings && settings.message }}
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters, mapState, mapActions } from 'vuex';
import BellIcon from '../assets/images/alarm-bell.svg';
import { name as appName } from '../config';
import ProductExportManager from './ProductExportManager.vue';
import ReadOnlyLabel from './Product/ReadOnlyLabel';
import GtmMixin from './GtmMixin';
import VersionDropdown from './VersionDropdown.vue';

export default {
  name: 'HeaderComponent',
  components: {
    BellIcon,
    ProductExportManager,
    ReadOnlyLabel,
    VersionDropdown
  },
  mixins: [GtmMixin],
  data: () => ({
    appName,
    announcements: [],
    showProductExportManager: false,
    selectedTenant: null,
    isProductReadOnly: false,
    displayMaintenanceMessage: false,
    menuOpen: true
  }),
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn',
      'isAdmin',
      'isOwner',
      'isGuest',
      'isSuperAdmin',
      'isImpersonated'
    ]),
    ...mapGetters('product', ['isReadOnly']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', { user: 'getUser' }),
    ...mapState('teams', ['teams', 'tenants']),
    ...mapState('maintenance', ['settings']),
    ...mapState('product', ['product', 'isLoading']),
    ...mapState('users', ['roles']),
    homeUrl() {
      return this.tenant ? `/t/${this.tenant}/` : '';
    },
    isReadOnlyMode() {
      return (
        this.isReadOnly ||
        (this.isGuest(this.product && this.product.team.slug) &&
          !this.isOwner())
      );
    },
    showProductName() {
      return (
        this.product &&
        this.$route.name &&
        this.$route.name.startsWith('product-')
      );
    },
    superAdminTitle() {
      return (this.roles && this.roles.find((r) => r.id === 1).label) || [];
    },
    selectedTeam() {
      return (
        this.teams &&
        this.teams.find(
          (t) => this.selectedTenant && t.tenant === this.selectedTenant
        )
      );
    },
    exportProperties() {
      return {
        name: this.product.name,
        type: this.product.type
      };
    },
    isProd() {
      return process.env.NODE_ENV === 'production';
    },
  },
  watch: {
    user: {
      async handler() {
        if (this.user) {
          await this.getSettings();
          if (!this.roles) await this.getRoles();
          if (this.isAdmin()) {
            if (!this.tenants) await this.loadTenants();
          }
          if (!this.teams) await this.loadTeams();
        }
      },
      immediate: true
    },
    product: {
      handler() {
        if (this.product && this.product.id) {
          this.fetchProductReleases();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('teams', ['loadTenants', 'loadTeams']),
    ...mapActions('users', ['getRoles']),
    ...mapActions('maintenance', ['getSettings']),
    ...mapActions('productRelease', ['fetchProductReleases']),
    closeExportWizard() {
      this.showProductExportManager = false;
    },
    exportProduct() {
      this.showProductExportManager = true;
    },
    getTenantList() {
      const tenants = this.isSuperAdmin()
        ? this.tenants && [...this.tenants]
        : this.user.teams && [
          ...this.user.teams.filter((t) => t.slug === t.tenant)
        ];
      return !tenants
        ? tenants
        : tenants.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
    },
    tenantAndRole(tenantId) {
      if (this.isSuperAdmin()) {
        return this.tenants.find((g) => g.id === tenantId).label;
      }
      const team =
        this.user.teams && this.user.teams.find((g) => g.id === tenantId);
      const role =
        this.roles &&
        this.roles.find((r) => r.id === team.UserTeam.team_role_id);
      return `${(team && team.label) || ''} (${(role && role.label) || ''})`;
    },
    toggleMainMenu() {
      this.$root.$emit('bv::toggle::collapse', 'main-menu');
      this.menuOpen = !this.menuOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

header {
  position: relative;
  z-index: 2;
  min-height: 34px;
  background: #f0f0f0;
  border-bottom: 1px solid rgba(43, 48, 52, 0.1);
  text-transform: uppercase;
  font-family: $headings-font-family;

  .el-dropdown {
    font-size: $font-size-base;
  }

  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-setting {
    color: $color-axa-blue;
  }

  .select-tenant {
    color: $color-gray--action;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background: #ffffff00 url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e') right 20px center/8px 10px no-repeat;
    text-transform: uppercase;
    max-width: 320px;
    text-overflow: ellipsis;
    max-height: 32px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  button .icon {
    height: 14px;
    width: 14px;
  }

  .app-name {
    color: $color-axa-blue;
  }

  .is-circle {
    width: 38px;
    height: 38px;
  }

  .logo {
    width: 50px;
    height: 50px;
    // Align logo to top
    display: inline-block;
    position: relative;
    top: -4px;
  }

  .helpIcon {
    font-size: 2em;
    margin-left: 0.8em;
  }

  .cartridge {
    margin-left: 1em;
    margin-right: 1em;
    padding: 0px 10px;
    background-color: gray;
    color: white;
    border-radius: 1em;
  }

  .top_separator {
    font-size: 0.8em;
    display: inline-block;
    padding-top: 0.2em;
    color: $color-gray--action;
  }
}

.ProductName {
  display: flex;
  align-items: center;

  >span {
    margin-right: 0.2em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ProductVersion {
  margin-left: 0.4rem;
}

.ProductRootline {
  display: flex;
  align-items: center;

  &__Breadcrumb {
    display: flex;
    align-items: center;
  }
}

.maintenance {
  width: 100%;
  padding: 8px;
  text-align: center;
}

.modeON {
  background: red;
  color: white;
}

.modeOFF {
  background: #53dcea;
  color: black;
}

.menu-button {
  background-color: $color-gray--light;
  border: none;
}
</style>
