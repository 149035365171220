<template>
  <div class="flex-grow-1 d-flex flex-column">
    <div v-selector.view class="container py-5">
      <div v-if="user">
        <h3>{{ $t('user.profile') }}</h3>
        <p class="py-3 ui_label_username">
          {{ $t('user.logged_in_as') }}
          <strong>{{ user.name || user.email }}</strong>
        </p>

        <button class="btn btn-ghost ui_button_logout" @click="logOutAndRedirect">
          {{ $t('user.logout') }}
        </button>

        <div v-if="enableTokens">
          <h3>{{ 'Tokens' }}</h3>

          <el-input v-model="tokenName" size="large" :placeholder="$t('token.insert_name')" style="width: 200px"
            class="tech_name mr-3 ui_input_product_tech_name" />

          <button class="btn btn-ghost" @click="generateToken">
            {{ 'generate Token' }}
          </button>

          <UiTable ref="table" :data="tokens" row-key="name" variant="padded">
            <!-- Name -->
            <el-table-column :label="$t('token.name')" prop="name" class-name="ui_token_name" :min-width="100">
            </el-table-column>

            <!-- Token -->
            <el-table-column :label="$t('token.token')" prop="hash" class-name="ui_token_name" :min-width="100">
            </el-table-column>

            <!-- Last Modified -->
            <el-table-column :label="$t('common.last_modified')" prop="last_used_at" class-name="ui_token_modified"
              :min-width="75">
              <template slot-scope="scope">{{ modified(scope.row) }}</template>
            </el-table-column>

            <!-- Operations -->
            <el-table-column width="180">
              <RowOperations slot-scope="{ $index }" :read-only="true" :is-read-only="false" :is-editable="false"
                class="ui_buttons_operations" @command="(command) => handleRowItemCommand(command, $index)" />
            </el-table-column>
          </UiTable>
          <div class="tokens-info">
            <h5>{{ $t('token.how-to-title') }}</h5>
            <p>{{ $t('token.how-to-text') }}</p>
            <code>{{ $t('token.how-to-example') }}</code>
          </div>
          <div>
            <h5>{{ $t('token.documentation-title') }}</h5>
            <button class="btn btn-ghost" @click="openSite('/api/documentation/ui/#/')">
              {{ 'API documentation' }}
            </button>
            <button class="btn btn-ghost" @click="
              openSite(
                'https://productmodeler.axa.com/dist/claims/prod/storybook/index.html'
              )
              ">
              {{ 'Widget documentation' }}
            </button>
          </div>
        </div>
      </div>
      <template v-if="!user">{{ $t('user.logged_out') }}</template>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import { mapState, mapGetters, mapActions } from 'vuex';
import RowOperations from '../components/RowOperations.vue';
import BaseTokensMixin from '../components/BaseTokensMixin';
import TokensMixin from '../components/TokensMixin';
import * as Role from '../domain/service/role';

export default {
  name: 'Profile',
  components: { RowOperations },
  mixins: [BaseTokensMixin, TokensMixin],
  data() {
    return {
      tokenName: '',
      tokenValue: '',
      MAX_TOKENS_PER_USER: 3,
    };
  },
  computed: {
    ...mapState('auth', ['tenant']),
    ...mapState('tokens', ['tokens']),
    ...mapGetters('auth', ['isGuest', 'isSuperAdmin']),
    ...mapGetters('auth', { user: 'getUser' }),
    enableTokens() {
      const findUserTeam = this.user.teams.find(
        (t) => t.tenant === this.tenant
      );
      const roleCurrentTenant = findUserTeam.UserTeam.tenant_role_id;
      return !Role.isGuest(roleCurrentTenant);
    },
  },
  mounted() {
    this.fetchTokens();
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('tokens', ['fetchTokens', 'createToken', 'deleteToken']),
    async logOutAndRedirect() {
      await this.logOut();
      Message.info('You have been logged out');
    },

    openSite(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped>
.el-input-number {
  width: 100%;
}

.el-button.el-tooltip.is-circle {
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 18px;
}

.icon-paper-write {
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.tokenDisplay {
  color: green;
  background: #efe;
  padding: 10px 25px 10px 25px;
}

.tokenValue {
  text-align: center;
  border: 1px solid green;
  padding: 10px 25px 10px 25px;
}

.icon-paper-write.disabled {
  cursor: default;
}

.el-icon-warning-outline {
  font-size: 20px;
  font-weight: bold;
}

.el-table>>>.inherited {
  color: #aaa;
}

.el-table>>>.overridden {
  font-style: italic;
}

.el-dialog__wrapper>>>.add-variable-dialog {
  width: 418px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
}

.el-dialog__wrapper>>>.el-dialog__header {
  padding: 15px;
  padding-bottom: 10px;
  background-color: transparent;
}

.el-dialog__wrapper>>>.el-dialog__body {
  padding: 10px 15px;
}

.italic {
  font-style: italic;
}

.creation-alert {
  margin: -1em 0 0 0;
}

.tokens-info {
  margin: 30px 0;
}
</style>
