<template>
  <div :class="'rules-editor-toolbar' + (visible ? '' : ' hidden')">
    <div v-if="isTable" class="icon-grid-button on mr-4 d-flex align-items-center" @click="toggleRuleDisplayType">
      <i :class="ruleDisplayTypeIcon"></i>
    </div>
    <div class="icon-grid-button d-flex align-items-center" :class="sidebarIsVisible ? 'on' : ''"
      @click="toggleSidebarVisibility">
      <i class="icon-layout-left"></i>
    </div>
    <div v-if="!testCoverageMode" class="icon-grid-button d-flex align-items-center"
      :class="inspectorIsVisible ? ' on' : ''" @click="toggleInspectorVisibility">
      <i class="icon-layout-right"></i>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'RulesEditorToolbar',
  props: {
    visible: Boolean,
    readOnly: Boolean,
    ruleId: { type: Number, default: undefined }
  },
  computed: {
    ...mapState('product', [
      'sidebarIsVisible',
      'inspectorIsVisible',
      'testCoverageMode',
      'ruleDisplayType',
      'product'
    ]),
    ...mapGetters('product', ['isReadOnly']),
    ...mapGetters('auth', ['isGuest']),
    isReadOnlyMode() {
      return this.isReadOnly;
    },
    isTable() {
      if (!this.product || !this.product.rules) return false;
      const rule = this.product.rules.find((r) => r.id === this.ruleId);
      return rule && rule.meta_data && rule.meta_data.source === 'XLSX';
    },
    ruleDisplayTypeIcon() {
      return this.ruleDisplayType === 'graph'
        ? 'icon-hierarchy-2'
        : 'icon-layout-module-2';
    }
  },
  methods: {
    ...mapActions('product', [
      'toggleSidebarVisibility',
      'toggleInspectorVisibility',
      'toggleRuleDisplayType'
    ])
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.rules-editor-toolbar {
  margin-left: 1rem;
  gap: 8px;
  display: flex;
}

.hidden {
  display: none;
}

i {
  font-size: 14px;
  cursor: pointer;
  color: $color-gray--action;
  padding: 5px 9px;
}

.icon-grid-button {
  height: 100%;

  &.on {
    border-bottom: 2px solid $color-orange-accent;

    i {
      margin-top: 2px;
      background-color: $color-active--20;
      color: $color-axa-blue;
    }
  }
}
</style>
