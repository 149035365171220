var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{ref:"importPropertyDialog",attrs:{"title":_vm.$t('properties.import-from-csp').toUpperCase(),"visible":_vm.isVisible,"custom-class":"add-variable-dialog"},on:{"close":function($event){return _vm.close()}}},[_c('UiSearchBox',{ref:"searchBox",attrs:{"placeholder":_vm.$t('product.search_properties')},on:{"input":_vm.filterProperties}}),_vm._v(" "),(_vm.propertiesAvailable)?_c('UiTable',{staticClass:"ui_table_properties",attrs:{"data-cy":"csp-properties","data":_vm.filteredProperties,"variant":"default"}},[_c('el-table-column',{attrs:{"label":"Name","sortable":true,"prop":"name","width":"500px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.getDimColor(row))?_c('span',{staticClass:"colorIndicator",style:('background-color: ' + _vm.getDimColor(row))}):_vm._e(),_vm._v(" "),_c('el-tooltip',{attrs:{"content":row.name,"placement":"bottom-start"}},[_c('span',[_vm._v(_vm._s(row.name))])])]}}],null,false,1662592397)}),_vm._v(" "),(_vm.columns.includes('definitionListId'))?_c('el-table-column',{attrs:{"label":"Definition list","sortable":true,"prop":"definitionListId","formatter":_vm.getDefinitionListName}}):_vm._e(),_vm._v(" "),(_vm.showPropertyType)?_c('el-table-column',{attrs:{"label":"Type","prop":"valueType"}}):_vm._e(),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Include","width":"90px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
          _vm.propertiesSelection[row.name] &&
          !_vm.propertiesSelection[row.name].compliant
        )?_c('el-tooltip',[_c('i',{staticClass:"el-icon-error"}),_vm._v(" "),_c('template',{slot:"content"},[_c('div',[_vm._v(_vm._s(_vm.$t(`properties.import-from-csp-invalid`)))])])],2):_vm._e(),_vm._v(" "),(
          _vm.propertiesSelection[row.name] &&
          _vm.propertiesSelection[row.name].compliant
        )?_c('UiToggle',{key:row.name,attrs:{"value":_vm.propertiesSelection[row.name].selected,"data-test":"import-property-toggle"},on:{"change":function($event){return _vm.toggleSelection(...arguments, row)}}}):_vm._e()]}}],null,false,178546180)})],1):_vm._e(),_vm._v(" "),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.isVisible = false}}},[_vm._v(_vm._s(_vm.$t('action.cancel')))]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.create}},[_vm._v(_vm._s(_vm.$t('action.ok'))+"\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }